import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Img from "../assets/img/myImg/LOGO-UScale.png";
import avatar1 from "../assets/img/avatar/avatar-1.png";
import { default as ReactSelect } from "react-select";
import env from "react-dotenv";
import axios from "axios";
import TopBar from "./TopBar";
import Sidebar from "./Sidebar";

function ViewOrgdetail({show}) {
    useEffect(() => {
        // console.log(params);
        if (params.id) {
            // console.log(params);
            getSurveyData();
            viewUser();
            
            // getSingleData();
        }
    }, []);

    let navigate = useNavigate();
    let params = useParams();
    let [orgName, setOrgName] = useState("");
    let [userName, setUserName] = useState("");
    let [assignSurvey, setAssignSurvey] = useState([])
    const [modSurveyData, setModSurveyData] = useState([]);
    const [optionSelected, setOptionSelected] = useState([]);

    let getSurveyData = async () => {
        let d = await axios.get(
            "https://apps.duredemos.com/uscale_server/getsurveylist"
        );
        // console.log("SurveyList:", d.data);
        let newArr = [];

        d.data.forEach((element) => {
            let obj = {
                label: element.survey_name,
                value: element.survey_id,
            };
            newArr.push(obj);
        });
        setModSurveyData([...newArr]);
    };

    let viewUser = async() => {
        let surData = await axios.get(
            `http://localhost:3004/uscale_server/vieworgsurvey/${params.id}`
        );
        // console.log("Assigned Survey:", surData.data);
        setAssignSurvey(surData.data)
    }


    let handleSave = async () => {
        if (optionSelected && optionSelected.length > 0) {
            let surveyIds = optionSelected.map((oj) => oj["value"]);
            await axios
                .post(`${env.API_URL}/editorg`, {
                    org_id: params.id,
                    org_name: orgName,
                    survey_id: surveyIds.join(","),
                    created_by: "Nilesh",
                    modified_by: "Nilesh",
                })
                .then((res) => {
                    // console.log(res);
                    navigate("/listing_orglist");
                });
        }
    };
    const getSelectedSurveys = (selected) => {
        // console.log(selected);
        setOptionSelected([...selected]);
        
    };
    useEffect(() => {
        if (modSurveyData && modSurveyData.length > 0) {
            // let url = "http://localhost:3011/singleorgdata/" + params.id;
            async function getOrgData() {
                await fetch(`${env.API_URL}/getorg/` + params.id)
                    .then((response) => response.json())
                    .then((data) => {
                        // console.log("here we get single", data);
                        if (data.length > 0) {
                            setOrgName(data[0].org_name);
                            let arr = data.map((oj) => oj["survey_id"]);
                            let newArr = [];
                            modSurveyData.forEach((ele) => {
                                if (arr.includes(ele.value)) {
                                    newArr.push(ele);
                                }
                            });
                            setOptionSelected([...newArr]);
                        }
                        //setUserName(data[0].user_name);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            getOrgData();
        }
    }, [modSurveyData]);
    let getSingleData = async () => {
        let url = env.API_URL+"/singleorgdata/" + params.id;

        await fetch(`${env.API_URL}/getorg/` + params.id)
            .then((response) => response.json())
            .then((data) => {
                // console.log("here we get single", data[0]);
                setOrgName(data[0].org_name);
                setUserName(data[0].user_name);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    return (
        <div>
            <div id="app">
                <div className="main-wrapper">
                    <div className="navbar-bg"></div>

                    <TopBar />
                    <Sidebar adminActive={show}/>

                    {/*   <!-- Main Content -->*/}

                    <div className="main-content">
                        <section className="section">
                            <div className="section-header">
                                <h1>Dashboard</h1>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="text-black-50">View organisation details</h4>
                                            <div
                                                className="card-header-action"
                                                onClick={() => navigate("/listing_orglist")}
                                            >
                                                <a href="" className="btn btn-primary">
                                                    View All Organisation
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <Form className="formContent">
                                                <Form.Group className="mb-3" controlId="formBasicName">
                                                    <Form.Label><b>Organisation Name :</b> {orgName}</Form.Label>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formBasicName">
                                                    <Form.Label><b>Assigned Surveys :</b> {optionSelected.map((ele) => ele.label).join(", ")}</Form.Label>
                                                    {/* {console.log("selected Hi",optionSelected.map((ele) => ele.label).join(", "))}                                                     */}
                                                </Form.Group> 
                                                <table className="table table-striped infoTable">
                                        
                                                        <tbody>
                                                                <tr>
                                                                    
                                                                </tr>
                                                        </tbody>
                                                </table>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <footer className="main-footer">
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default ViewOrgdetail