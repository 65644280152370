import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Img from "../assets/img/myImg/LOGO-UScale.png";
import avatar1 from "../assets/img/avatar/avatar-1.png";
import { default as ReactSelect } from "react-select";
import env from "react-dotenv";
import TopBar from "./TopBar";
import Sidebar from "./Sidebar";

function AddOrganisation({show}) {
  let [orgId, setOrgId] = useState("");
  let [orgName, setOrgName] = useState("");
  let [orgType, setOrgType] = useState("");
  const [disableBut, setDisableBut] = useState(true);
  //   let [surData, setSurData] = useState([]);
  const [modSurveyData, setModSurveyData] = useState([]);
  const [selectedSur, setSelectedSur] = useState([]);
  let [value, setValue] = useState("");
  let [label, setLabel] = useState("");
  let userName = sessionStorage.getItem("name");

  let navigate = useNavigate();

  useEffect(() => {
    if(sessionStorage.getItem('loggedIn') != 'true'){
      navigate('/login')
  }
    getSurveyData();
    sideClass()
  }, []);

  const getSelectedSurveys = (selected) => {
    // console.log(selected);
    if (selected && selected.length > 0) {
      let arr = [];
      selected.forEach((ele) => {
        arr.push(ele.value);
      });
      setSelectedSur([...arr]);
    } else {
      setSelectedSur([]);
    }
  };
  useEffect(() => {
    if (selectedSur && selectedSur.length > 0) {
      setDisableBut(false);
    } else {
      setDisableBut(true);
    }
  }, [selectedSur]);
  let getSurveyData = async () => {
    let d = await axios.get(
      `${env.API_URL}/getsurveylist`
    );
    // console.log("SurveyList:", d.data);
    let newArr = [];

    d.data.forEach((element) => {
      let obj = {
        label: element.survey_name,
        value: element.survey_id,
      };
      newArr.push(obj);
    });
    setModSurveyData([...newArr]);
    // setSurData(d.data);
  };
  let handleSave = async (e) => {
    e.preventDefault();
    try {
      if (selectedSur && selectedSur.length > 0 && orgName != "") {
        //selectedSur.forEach(async (surveyId) => {
        let surveyIds = selectedSur.join(",");
        await axios
          .post(`${env.API_URL}/addorg`, {
            // "org_id": orgId,
            org_name: orgName,
            survey_id: surveyIds,
            // created_by: "Nilesh",
            // modified_by: "Nilesh",
            created_by: sessionStorage.getItem('name'),
            modified_by: sessionStorage.getItem('name'),
          })
          .then((res) => {
            // console.log(res);
            if (res.data.status === "FAIL") {
              alert(res.data.message);
            } else {
              navigate("/listing_orglist");
            }
          });
        // });
      } else {
        alert("All fileds are mandatory");
      }
    } catch (error) {
      // console.log(error);
    }
  };

  let sideClass = () => {
        
    if (document.body.classList.contains('sidebar-show')) {
        // console.log("doc",document.body.classList)
        document.body.classList.remove('sidebar-show');
        document.body.classList.add('sidebar-mini');
    } 
    else if (document.body.classList.contains('sidebar-mini')) {
        // document.body.classList.remove('sidebar-mini');
        document.body.classList.add('sidebar-mini');
    }
    else if (window.innerWidth < 767){
        document.body.classList.remove('sidebar-mini')
        // console.log("doc",document.body.classList)
        document.body.classList.remove('sidebar-show')
    }

  }




  return (
    <div>
      <div id="app">
        <div className="main-wrapper">
          <div className="navbar-bg landing_nav"></div>
          <TopBar/>
          <Sidebar adminActive={show}/>
          {/*   <!-- Main Content -->*/}

          <div className="main-content" onClick={sideClass}>
            <section className="section">
              <div className="section-header">
                <h1>Dashboard</h1>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="text-black-50">Add Organisation</h4>
                      <div
                        className="card-header-action"
                        onClick={() => navigate("/listing_orglist")}
                      >
                        <a href="" className="btn btn-primary">
                          View All Organisation
                        </a>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      {modSurveyData.length > 0 && (
                        <Form className="formContent">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>Organisation Name</Form.Label>
                            <Form.Control
                              onChange={(e) => setOrgName(e.target.value)}
                              type="text"
                              placeholder="Organisation name"
                              name="orgName"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>Select Surveys : </Form.Label>

                            <ReactSelect
                              className="surevyDropDown"
                              isMulti
                              allowSelectAll={true}
                              options={modSurveyData}
                              onChange={getSelectedSurveys}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                            />
                          </Form.Group>
                          <Button
                            variant="primary"
                            disabled={disableBut ? true : false}
                            onClick={handleSave}
                          >
                            Submit
                          </Button>
                        </Form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <footer className="main-footer">
            {/* 
            <!-- <div className="footer-left">
              Copyright &copy; 2018 <div className="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval
                Azhar</a>
            </div>
            <div className="footer-right">
              2.3.0
            </div> -->
        */}
          </footer>
        </div>
      </div>
    </div>
  );
}

export default AddOrganisation;
