import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import avatar1 from "../assets/img/avatar/avatar-1.png";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    fab: {
      margin: theme.spacing(2),
    },
    absolute: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
  }));



function SampleTopBar({ iconDisplay,showSurvey,showadminSurvey,showSubAdminSurvey,showSubModule,p,childToParent ,childToParentAdmin }) {

    const classes = useStyles();

    let navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [count, setCount] = useState(0)
    const open = Boolean(anchorEl);
    const [name, setName] = useState("");

    useEffect(() => {
        document.body.classList.add('bg-grey');
        document.body.classList.add('sidebar-show');
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogClose = () => {
        setAnchorEl(null);
        sessionStorage.setItem('name','')
        sessionStorage.removeItem('name')
        sessionStorage.removeItem('loggedIn')
        navigate('/login')
    };

    // useEffect(() => {
    //     if(count == 0 ){
    //         document.body.classList.add('sidebar-show');
    //     }
    //     if(count == 1){
    //         document.body.classList.remove('sidebar-mini');
    //         document.body.classList.add('sidebar-show');
    //     }     
    // },[count])

    let addClass = () => {
        if (document.body.classList.contains('sidebar-show')) {
            document.body.classList.remove('sidebar-show');
            document.body.classList.add('sidebar-mini');
        } else if (document.body.classList.contains('sidebar-mini')) {
            document.body.classList.remove('sidebar-mini');
            document.body.classList.add('sidebar-show');
        }else if (window.innerWidth < 767){
            document.body.classList.remove('sidebar-mini')
            
            document.body.classList.remove('sidebar-gone')
        }

    }


    return (

        <nav className="navbar navbar-expand-lg main-navbar">
            <form className="form-inline mr-auto">
                <ul className="navbar-nav mr-3">
                    {/* <li>
                        {iconDisplay ? "": <a
                            href="#"
                            data-toggle="sidebar"
                            className="nav-link nav-link-lg"
                            onClick={addClass}
                        >
                            <i className="fas fa-bars"></i>
                        </a>
                        }
                        
                    </li> */}
                    <li>
                        <a
                            href="#"
                            data-toggle="search"
                            className="nav-link nav-link-lg d-sm-none"
                        >
                            <i className="fas fa-search"></i>
                        </a>

                    </li>
                </ul>
                
            </form>
            <ul className="navbar-nav navbar-right">
                {/* <li>
                <Tooltip title="Previous page" className='homeTooltip'>
                <IconButton aria-label="home" className="Previouspage">
                    <i class="fa fa-arrow-left backIcon" aria-hidden="true" onClick={() => navigate('/')}></i>
                </IconButton>
                </Tooltip>
                </li> */}
                {showSurvey ? 
                <Tooltip title="All Survey" className="homeTooltip AllSurvey">
                <IconButton aria-label="home" className="AllSurvey" onClick={() => navigate(`/`)}>
                    <i class="fa fa-home homeIcon" aria-hidden="true" onClick={() => navigate(`/`)}></i>
                </IconButton>
                </Tooltip>:
                "" 
                }
                 {showSubModule ? 
                <Tooltip title="Previous page" className='homeTooltip'>
                <IconButton aria-label="home" className="Previouspage" onClick={() => childToParent(false)}>
                    <i class="fa fa-arrow-left backIcon" aria-hidden="true" onClick={() => childToParent(false)}></i>
                </IconButton>
                </Tooltip>
                :"" }
                
            </ul>
        </nav>
    )
}

export default SampleTopBar