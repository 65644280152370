import React from 'react'
import register from '../../assets/img/unsplash/register.svg'
import Logo from "../../assets/img/myImg/LOGO-UScale.png";

function Register() {
    return (
        <div>
            <section class="section">
                <div class="d-flex flex-wrap align-items-stretch">
                    <div class="col-lg-7 col-12 order-lg-1 order-1 log-img">
                        <div class="login-img"><img src={register} class="w-75" /></div>
                    </div>
                    <div class="col-lg-5 col-md-6 col-12 order-lg-2 min-vh-100 order-2 bg-white">
                        <div class="p-4 mt-3 d-flex flex-column align-items-lg-stretch justify-content-center h-100">
                            <img src={Logo} alt="logo" class="w-25 mb-5 mt-2" />
                            <h4 class="text-dark font-weight-normal">Welcome to <span class="font-weight-bold">UScale</span></h4>
                            <p class="para-content">Before you get started, you must login or register if you don't already have an
                                account.</p>
                            <div class="card shadow-none pt-0">
                                <div class="card-header pl-0 pt-0">
                                    <h4 class="text-black-50">Register</h4>
                                </div>
                                <div class="card-body pl-0 pt-0">
                                    <form method="POST">
                                        <div class="row">
                                            <div class="form-group col-6">
                                                <label for="first_name">First Name</label>
                                                <input id="first_name" type="text" class="form-control" name="first_name" autofocus />
                                            </div>
                                            <div class="form-group col-6">
                                                <label for="last_name">Last Name</label>
                                                <input id="last_name" type="text" class="form-control" name="last_name" />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="email">Email</label>
                                            <input id="email" type="email" class="form-control" name="email" />
                                            <div class="invalid-feedback">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-12">
                                                <label for="organization" class="d-block">Organization</label>
                                                <input id="organization" type="text" class="form-control" name="organization" />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" name="agree" class="custom-control-input" id="agree" />
                                                <label class="custom-control-label" for="agree">I agree with the terms and conditions</label>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <button type="submit" class="btn btn-primary  btn-block">
                                                Register
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Register