import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Img from "../assets/img/myImg/LOGO-UScale.png";
import avatar1 from "../assets/img/avatar/avatar-1.png";
import { default as ReactSelect } from "react-select";
import env from "react-dotenv";
import axios from "axios";
import TopBar from "./TopBar";
import Sidebar from "./Sidebar";

function ViewUser({show}) {

    useEffect(() => {
        // console.log(params);
        if (params.id) {
            // console.log(params);
        }
    }, []);

    let params = useParams();
    let navigate = useNavigate();
    let [data, setData] = useState([]);
    let [surData, setSurData] = useState([]);
    let [userData, setUserData] = useState([]);
    let [orgName, setOrgName] = useState("");
    let [userName, setUserName] = useState("");
    let [mainArr, setMainArr] = useState([])

    const [modUserData, setModUserData] = useState([]);
    const [optionSelected, setOptionSelected] = useState([]);

    useEffect(() => {
        // console.log(env.API_URL)
        if (params) {
            // console.log(params.id)
        }
        getData()
        getSurveyData()

    }, [])

    let getData = async () => {
        let d = await axios.get(`${env.API_URL}/getOrgList`);
        setData(d.data);
        // console.log(d.data)
    };

    let getSurveyData = async () => {
        let res = await axios.get(`${env.API_URL}/getallsurveydata`);
        // console.log("SurveyList:", res.data);
        setSurData(res.data);
    };

    let getSingleUserData = async (id) => {
        let resp = await axios.get(`${env.API_URL}/getsingleuserdata/${id}`);
        // console.log("singledata:", resp.data);
        setUserData(resp.data);
    };

    useEffect(() => {
        if (data.length > 0 && surData.length > 0) {
            getSingleUserData(params.id)
        }
        // console.log("print",userData[0].user_name)
    }, [data, surData])

    useEffect(() => {
        let newArr = []
        let temp = ''
        if (userData.length > 0) {
            userData.map((item) => {
                if (item.survey_id != temp) {
                    temp = item.survey_id;
                    newArr.push(item.survey_id)
                }
            })
        }
        // console.log("arr", newArr)
        let newArrObj = [];
        newArr.forEach((ele) => {
            // let surveyName = userData.filter((val) => {
            //     if (val.survey_id == ele) {
            //         return val.survey_id
            //     }
            // })

            let surveyName = surData.filter((val) => {
                if (val.survey_id == ele) {
                    return val.survey_name
                }
            })

            let filteredData = surData.filter((val) => {
                if (val.survey_id == ele) {
                    return val.survey_txn_id

                }
            })
            // console.log(surveyName)
            let modIdArr = [];
            if (filteredData.length > 0) {
                let name = ''
                filteredData.forEach((obj) => {
                    name = obj.survey_name
                    if (!modIdArr.includes(obj.tabname)) {
                        modIdArr.push(obj.tabname)
                    }
                })
                newArrObj.push({ surveyId: name, subModuleList: modIdArr })
            }
            // newArrObj.push({ surveyId: ele, subModuleList: modIdArr })
        })
        // console.log("newArrObj", newArrObj)
        setMainArr(newArrObj)

        // let temp1 = []
        // newArrObj.map((val) => {
        // // {
        //     console.log(val.subModuleList)
        //     val.subModuleList.map((item) =>
        //         surData.map( (ele) => {
        //         if(item == ele.survey_txn_id){
        //             temp1.push(val.surveyId+' - '+ele.tabname)
        //         }
        //     })
        //     )
        // }
        // )
        // console.log("new",temp1)
    }, [surData, userData])


    return (
        <div>
            <div id="app">
                <div className="main-wrapper">
                    <div className="navbar-bg"></div>

                    <TopBar />
                    <Sidebar adminActive={show}/>

                    {/*   <!-- Main Content -->*/}

                    <div className="main-content">
                        <section className="section">
                            <div className="section-header">
                                <h1>Dashboard</h1>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <Form className="formContent">
                                                <div className="card-header">

                                                    <h4 className="text-black-50">User's details</h4>
                                                    <div
                                                        className="card-header-action"
                                                        onClick={() => navigate("/listing_programs")}
                                                    >
                                                        <a href="" className="btn btn-primary">
                                                            View All Users
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="card-body p-0">
                                                    <>
                                                        <div className="personalInfo">
                                                                            <div style={{fontSize:"16px"}}><b>User Name:&nbsp;&nbsp;</b>
                                                                            {userData && userData.length > 0 ? userData[0].user_name : "Not exist"} </div>     
                                                                            <div style={{fontSize:"16px"}}><b>User Email Id:&nbsp;&nbsp;</b>
                                                                            {userData && userData.length > 0 ? userData[0].emailid : "Not exist"} </div>
                                                            {/* <p>User Name:&nbsp;&nbsp;{userData && userData.length > 0 ? userData[0].user_name : "Not exist"} </p>
                                                            <p>User Email Id:&nbsp;&nbsp;{userData && userData.length > 0 ? userData[0].emailid : "Not exist"} </p> */}
                                                        </div>
                                                        <table className="table table-striped infoTable">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Survey Name</th>
                                                                    <th scope="col">Assigned Submodule</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    mainArr && mainArr.map((ele) => {
                                                                        return <>
                                                                            <tr scope="row">
                                                                                <td>{ele.surveyId}</td>
                                                                                {/* <td>{ele.subModuleList.join(", ")}</td> */}
                                                                                <td><ul className="listName">
                                                                                    {ele.subModuleList.map((ele) =>{
                                                                                    return <>
                                                                                        <li>{ele.charAt(0).toUpperCase() + ele.slice(1)}</li>
                                                                                    </>
                                                                                })}
                                                                                </ul>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        {/* {
                                            mainArr && mainArr.map( (ele) => {
                                                    return <>
                                                        <p><span>Survey Name: </span>{ele.surveyId}</p>
                                                        
                                                        <p>{ele.subModuleList.map( (item) => {
                                                            return <>
                                                            <ul>
                                                                <li>{item}</li>
                                                            </ul>
                                                                
                                                            </>
                                                        })}</p>
                                                    </>
                                                })
                                            } */}
                                                    </>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <footer className="main-footer">
                        {/* 
        <!-- <div className="footer-left">
          Copyright &copy; 2018 <div className="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval
            Azhar</a>
        </div>
        <div className="footer-right">
          2.3.0
        </div> -->
    */}
                    </footer>
                </div>
            </div>
        </div>
    )
}

export default ViewUser