import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import avatar1 from "../assets/img/avatar/avatar-1.png";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { default as ReactSelect } from "react-select";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import env from "react-dotenv";
import * as XLSX from "xlsx/xlsx";
import Loader from "./Loader";
import axios from "axios";
// import { getEMSP } from '../../../server/src/userModel';
// import { getChargingLocation, getEMSP } from '../../../server/src/userModel';

const baseUrl = env.API_URL;
// const baseUrl = 'http://localhost:3004/uscale_server';

const useStyles = makeStyles((theme) => ({
    fab: {
      margin: theme.spacing(2),
    },
    absolute: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
  }));



function TopBar({ iconDisplay,showSurvey,showadminSurvey,showDownload, showSubAdminSurvey,showSubModule,p,childToParent ,childToParentAdmin }) {
    const [showDownloadForm, setShow] = useState(false)
    const classes = useStyles();

    let navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [count, setCount] = useState(0)
    const open = Boolean(anchorEl);
    const [name, setName] = useState("");
    const [filters, setFilters] = useState([])
    let [tabOptions, setTabNameOptions] = useState([]);
    const [tabName, setTabName] = useState("");
    const [subTabName, setSubTabName] = useState("");
    let [subTabNameOptions, setSubTabNameOptions] = useState([]);
    let [CategoryOptions, setCategoryOptions] = useState([]);
    let [brandOptions, setBrandOptions] = useState([]);
    let [modelOptions, setModelOptions] = useState([]);
    let [yearOptions, setYearOptions] = useState([]);
    let [countryOptions, setCountryOptions] = useState([]);
    let [locationOptions, setLocationOptions] = useState([]);
    let [emspOptions, setEmspOptions] = useState([]);
    let [statesOptions, setStatesOptions] = useState([]);
    let [categoryname, setCategoryName] = useState('');
    let [statesName, setStatesName] = useState('');
    let [countryName, setCountryName] = useState('');
    let [emspName, setEmspName] = useState('');
    let [locationName, setLocationName] = useState('');
    let [brand, setBrand] = useState('All');
    let [model, setModel] = useState('All');
    let [year, setYear] = useState('All');
    const [showLoader, setShowLoader] = useState(false);
    let [subCategoryOptions,setSubCategoryOptions] = useState([]);
    let [subCategoryName,setSubCategoryName] = useState('');

    useEffect(() => {
        document.body.classList.add('bg-grey');
        document.body.classList.add('sidebar-show');
        addClass()
    }, [])


    const createFilters = (data) => {
      if(!tabOptions[0]) {
        return null;
      }
        return (
        <div class="row mb-3 mt-3">
        <label class="col-sm-5 col-form-label">{transformToUpperCase(data.survey_filter, ['-'])}</label>
        <div class="col-sm-7">
        <ReactSelect
            className="surevyDropDown"
            allowSelectAll={true}
            isMulti={!(data.survey_filter == 'TAB NAME' || data.survey_filter == 'SUB-TAB NAME' || data.survey_filter == 'CATEGORY' || data.survey_filter == 'SUBCATEGORY')}
            options={
                data.survey_filter == 'TAB NAME'
                ? tabOptions
                  : data.survey_filter == 'SUB-TAB NAME'
                  ? subTabNameOptions
                    : data.survey_filter == 'CATEGORY'
                    ? CategoryOptions
                      : data.survey_filter == 'SUBCATEGORY'
                      ? subCategoryOptions
                        : data.survey_filter == 'BRAND'
                        ? brandOptions
                          : data.survey_filter == 'MODEL'
                          ? modelOptions
                             : data.survey_filter == 'REGISTRATION YEAR'
                             ? yearOptions 
                              : data.survey_filter == 'CHARGING LOCATION'
                                ? locationOptions
                                  : data.survey_filter == 'COUNTRY'
                                  ? countryOptions
                                    : data.survey_filter == 'EMSP GROUP'
                                    ? emspOptions
                                      : data.survey_filter == 'US STATES'
                                      ? statesOptions
                                        : []}
            onChange={
                data.survey_filter == 'TAB NAME'
                ? getSelectedTab
                  : data.survey_filter == 'SUB-TAB NAME'
                  ? getSelectedSubTab
                    : data.survey_filter == 'CATEGORY'
                    ? getSelectedCategory
                      : data.survey_filter == 'SUBCATEGORY'
                      ? getSelectedSubCategory
                      : data.survey_filter == 'BRAND'
                        ? getSelectedBrand
                        : data.survey_filter == 'MODEL'
                          ? getSelectedModel
                            : data.survey_filter == 'REGISTRATION YEAR'
                            ? getSelectedYear 
                              : data.survey_filter == 'CHARGING LOCATION'
                              ? getChargingLocation
                                : data.survey_filter == 'COUNTRY'
                                ? getSelectedCountry
                                  : data.survey_filter == 'EMSP GROUP'
                                  ? getEMSP
                                    : data.survey_filter == 'US STATES'
                                    ? getStates
                                      : []}
            closeMenuOnSelect={true}
            defaultValue={data.survey_filter == 'TAB NAME' ? { label: tabOptions[0] ? tabOptions[0].label : '', value: tabOptions[0] ? tabOptions[0].value : ''} : ''}
        />
        </div>
    </div>
        )
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSubmit = async () => {
        const sId = window.localStorage.getItem('sid')
        const data = {
            surveyId: sId,
            brandName: brand == 'All' ? '' : brand,
            modelName: model == 'All' ? '' : model,
            yearName: year == 'All' ? '' : year,
            tabName: tabName,
            subTabName: subTabName,
            categoryName: categoryname,
            subCategoryName: subCategoryName,
            locationName: locationName,
            countryName: countryName ? countryName : statesName,
            emspName: emspName,
            // statesName: statesName,
        }
        setShowLoader(true)
        let result = await axios.post(`${baseUrl}/submit`, data);
        exportCSVFile(Object.keys(result.data[0][0]), result.data[0], 'report')
        setShowLoader(false)
        setShow(false)
    }

    const handleLogClose = () => {
        setAnchorEl(null);
        sessionStorage.setItem('name','')
        sessionStorage.removeItem('name')
        sessionStorage.removeItem('loggedIn')
        navigate('/login')
    };

    const getTabNames = async () => {
        const sId = window.localStorage.getItem('sid')
        const subId = window.localStorage.getItem('subModId')
        const options = [];
        const option = [];
        const options1 = [];
        const options2 = [];
        const options3 = [];
        let filters = await axios.get(`${baseUrl}/getFilterNames/${sId}`);
        setFilters(filters.data)
        let d = await axios.get(`${baseUrl}/getTabNames/${subId}`);
        d.data.map((tab) => {
          options.push({label: tab.tabname, value: tab.tabname})
        })
        setTabNameOptions(options);
        setTabName(options[0].label);
        getSubTabNames();
        let getAdvanced = await axios.get(`${baseUrl}/getAdvancedFilters/${sId}`);
        let getBrands = await axios.get(`${baseUrl}/getAdvancedFiltersBrands/${brand}`);
        getBrands.data.map((tab) => {
            options1.push({label: tab.brand_atext, value: tab.brand_atext})
        })
        setBrandOptions(options1);
        let getModels = await axios.get(`${baseUrl}/getAdvancedFiltersModels/${model}`);
        getModels.data.map((tab) => {
            options2.push({label: tab.model_atext, value: tab.model_atext})
        })
        setModelOptions(options2);
        let getYears = await axios.get(`${baseUrl}/getAdvancedFiltersYears/${year}`);
        getYears.data.map((tab) => {
            options3.push({label: tab.year_atext, value: tab.year_atext})
        })
        setYearOptions(options3);

        if(sId == 9) {
          getEmspFilterOptions();
          getLocationOptions();
        } else if(sId == 8) {
          getLocationOptions();
          getCountryOptions();
        } else if(sId == 11) {
          getLocationOptions();
          getStatesOptions();
        } else if(sId == 12) {
          getLocationOptions();
          getStatesOptions();
        } else if(sId == 7) {
          getLocationOptions();
          getCountryOptions();
        }
      };

      
      const getEmspFilterOptions = async (selected) => {
      const options = [];
      let getEmsp = await axios.get(`${baseUrl}/getAdvancedFiltersemsp`);
          getEmsp.data.map((tab) => {
              options.push({label: tab.emsp_group_atext, value: tab.emsp_group_atext})
          })
      setEmspOptions(options)
      }

      const getLocationOptions = async (selected) => {
        const options = [];
        let getChargingLocation = await axios.get(`${baseUrl}/getAdvancedFilterslocationName`);
          getChargingLocation.data.map((tab) => {
              options.push({label: tab.charg_atext, value: tab.charg_atext})
          })
          setLocationOptions(options)
        }

        const getStatesOptions = async (selected) => {
          const options = [];
          let getstates = await axios.get(`${baseUrl}/getAdvancedFiltersusStateName`);
            getstates.data.map((tab) => {
                options.push({label: tab.us_states, value: tab.us_states})
            })
            setStatesOptions(options)
          }

          const getCountryOptions = async (selected) => {
            const options = [];
            let getCountry = await axios.get(`${baseUrl}/getAdvancedFilterscountry`);
              getCountry.data.map((tab) => {
                  options.push({label: tab.country_atext, value: tab.country_atext})
              })
              setCountryOptions(options)
            }

      const getSelectedTab = async (selected) => {
        setTabName(selected.value);
      };

      const getSelectedBrand = async (selected) => {
        const val = selected.map((element) => element.label).join(',')
        setBrand(val);
        let options2 = []
        let getModels = await axios.get(`${baseUrl}/getAdvancedFiltersModels/${val}`);
        getModels.data.map((tab) => {
            options2.push({label: tab.model_atext, value: tab.model_atext})
        })
        setModelOptions(options2);
      };

      const getSelectedModel = async (selected) => {
        const val = selected.map((element) => element.label).join(',')
        setModel(val);
        let options3 = []
        let getYears = await axios.get(`${baseUrl}/getAdvancedFiltersYears/${val}`);
        getYears.data.map((tab) => {
            options3.push({label: tab.year_atext, value: tab.year_atext})
        })
        setYearOptions(options3);
      };

      const getSelectedYear = async (selected) => {
        const val = selected.map((element) => element.label).join(',')
        setYear(val);
      };

      const getSelectedSubTab = async (selected) => {
       setSubTabName(selected.value);
       
      };

      const getSelectedCategory = async (selected) => {
        setCategoryName(selected.value);
      };

      const getSelectedSubCategory = async (selected) => {
        setSubCategoryName(selected.value);
      };

      const getSelectedCountry = async (selected) => {
        const val = selected.map((element) => element.label).join(',')
        setCountryName(val);
      };

      const getStates = async (selected) => {
        const val = selected.map((element) => element.label).join(',')
        setStatesName(val);
      }; 

      const getChargingLocation = async (selected) => {
        const val = selected.map((element) => element.label).join(',')
        setLocationName(val);
      };
      
      const getEMSP = async (selected) => {
        const val = selected.map((element) => element.label).join(',')
        setEmspName(val);
      };

      function convertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';
    
        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','
    
                line += array[i][index];
            }
    
            str += line + '\r\n';
        }
    
        return str;
    }
    
    function exportCSVFile(headers, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }
        var jsonObject = JSON.stringify(items);
    
        var csv = convertToCSV(jsonObject);
        

        //trial

        
        const workbook = XLSX.read(csv, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const xlsxFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        var exportedFilenmae = fileTitle + '.xlsx' || 'export.csv';
        const blob = new Blob([xlsxFile], { type: 'application/octet-stream' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

      const getSubTabNames = async () => {
        const options = [];
        const sId = window.localStorage.getItem('sid')
        const data = {
            surveyId: sId,
            tabName: tabName,
        }
        let d = await axios.post(`${baseUrl}/getSubTabNames`, data);
        d.data.map((tab) => {
          options.push({label: tab.subtabname, value: tab.subtabname})
        })
        setSubTabNameOptions(options);
      };

      const getSubCategoryNames = async () => {
        const options = [];
        const sId = window.localStorage.getItem('sid')
        const data = {
            surveyId: sId,
            tabName: tabName,
            subTabName: subTabName,
            category: categoryname,
        }
        let d = await axios.post(`${baseUrl}/getSubCategoryNames`, data);
        d.data.map((tab) => {
          options.push({label: tab.subcatgory, value: tab.subcatgory})
        })
        setSubCategoryOptions(options);
      };

      const transformToUpperCase = (str, separators) =>  {
        separators = separators || [ ' ' ];
        var regex = new RegExp('(^|[' + separators.join('') + '])(\\w)', 'g');
        return str.toLowerCase().replace(regex, function(x) { return x.toUpperCase(); });
      }

      const getCategoryNames = async () => {
        const options = [];
        const sId = window.localStorage.getItem('sid')
        const data = {
            surveyId: sId,
            tabName: tabName,
            subTabName: subTabName
        }
        let d = await axios.post(`${baseUrl}/getCategoryNames`, data);
        d.data.map((tab) => {
          options.push({label: tab.category, value: tab.category})
        })
        setCategoryOptions(options);
      };

    
      useEffect(() => {
        if (showDownloadForm) {
          getTabNames();
        } else {
          console.log(showDownloadForm)
        }
      }, [showDownloadForm])

      useEffect(() => {
        if(tabName) {
        getSubTabNames();
        }
      }, [tabName])

      useEffect(() => {
        if(subTabName) {
        getCategoryNames();
        }
      }, [subTabName])

      useEffect(() => {
        if(categoryname) {
        getSubCategoryNames();
        }
      }, [categoryname])

    let addClass = () => {
        
        if (document.body.classList.contains('sidebar-show')) {
            // console.log("doc",document.body.classList)
            document.body.classList.remove('sidebar-show');
            document.body.classList.add('sidebar-mini');
        } else if (document.body.classList.contains('sidebar-mini')) {
            document.body.classList.remove('sidebar-mini');
            document.body.classList.add('sidebar-show');
        }else if (window.innerWidth < 767){
            document.body.classList.remove('sidebar-mini')
            // console.log("doc",document.body.classList)
            document.body.classList.remove('sidebar-show')
        }

    }


    return (

        <nav className="navbar navbar-expand-lg main-navbar">
            {showDownloadForm && 
             (
             <div className="formDiv">
                <div className="downloadForm"></div>
                <form className="FormContent">
                {showLoader ? <div className="loaderWrapper1"><Loader data={showLoader}/></div> :
                <>
                <div class="row mb-3 mt-1 cross" onClick={() => setShow(false)}>X</div>
                {filters.map((filter) => createFilters(filter)
                 )}
                 <div class="row mb-3 mt-2">
                    <button className='btn btn-primary submit' type="button" onClick={() => onSubmit()}>SUBMIT</button>
                 </div>
                 </>}
                </form>
              </div>
             )}
            <form className="form-inline mr-auto">
                <ul className="navbar-nav mr-3">
                    <li>
                        {iconDisplay ? "": <a
                            href="#"
                            data-toggle="sidebar"
                            className="nav-link nav-link-lg"
                            onClick={addClass}
                        >
                            <i className="fas fa-bars"></i>
                        </a>
                        }
                        
                    </li>
                    <li>
                        <a
                            href="#"
                            data-toggle="search"
                            className="searchIcon nav-link nav-link-lg d-sm-none"
                        >
                            <i className="fas fa-search"></i>
                        </a>

                    </li>
                </ul>
                
            </form>
            <ul className="navbar-nav navbar-right">
                <li>
                {showDownload ?
                  <Tooltip title="Download reports and data" className="homeTooltip">
                  <IconButton aria-label="download" className="AllSurvey"  style={{backgroundColor:"white !important"}} >
                    <i class="fa fa-download homeIcon" aria-hidden="true" onClick={() => setShow(true)}></i>
                  </IconButton></Tooltip> : ''
                }
                {showSurvey ? 
                <Tooltip title="All Survey" className="homeTooltip AllSurvey">
                <IconButton aria-label="home" className="AllSurvey" onClick={() => navigate(`/landinguser`)}>
                    {/* <i class="fa fa-home homeIcon" aria-hidden="true" onClick={() => navigate(`/landinguser/${p}`)}></i> */}
                    {/* new code as we are not using anything in url removed p*/}
                    <i class="fa fa-home homeIcon" aria-hidden="true" onClick={() => navigate(`/landinguser`)}></i>

                </IconButton>
                </Tooltip>:
                showadminSurvey? 
                <Tooltip title="All Survey" className="homeTooltip">
                <IconButton aria-label="home" className="AllSurvey" onClick={() => navigate(`/landingadmin`)} style={{backgroundColor:"white !important"}} >
                    <i class="fa fa-home homeIcon" aria-hidden="true" onClick={() => navigate(`/landingadmin`)}></i>
                </IconButton>
                </Tooltip>:
                "" }
                {showSubModule ? 
                <Tooltip title="Previous page" className='homeTooltip'>
                <IconButton aria-label="home" className="Previouspage" onClick={() => {
                        childToParent(false);
                        localStorage.removeItem("key");
                    }} >
                    <i class="fa fa-arrow-left backIcon" aria-hidden="true" onClick={() => {
                        childToParent(false);
                        localStorage.removeItem("key");
                        // console.log("pranit")
                    }}></i>
                </IconButton>
                </Tooltip>
                :
                showSubAdminSurvey?
                <Tooltip title="Previous page" className='homeTooltip'>
                <IconButton aria-label="home" className="Previouspage" onClick={() => childToParentAdmin(false)}>
                    <i class="fa fa-arrow-left backIcon" aria-hidden="true" onClick={() => childToParentAdmin(false)}></i>
                </IconButton>
                </Tooltip>
                :"" }
                </li>
                
                <li className="dropdown" >
                    <a
                        href="#"
                        data-toggle="dropdown"
                        className="nav-link dropdown-toggle nav-link-lg nav-link-user"
                        aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}
                        
                    >
                        <img
                            alt="image"
                            src={avatar1}
                            className="rounded-circle mr-1"
                            
                        />
                        <div className="d-sm-none d-lg-inline-block userNameIcon">
                            {sessionStorage.getItem('name').charAt(0).toUpperCase() + sessionStorage.getItem('name').slice(1)}</div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                    </div>
                    <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                        className="menuLogout"
                    >   
                        <MenuItem 
                        className="menuLogoutOption"
                        >
                            <a href="#" className="dropdown-item has-icon text-danger proFileBtn">
                            <i class="fas fa-user" aria-hidden="true"></i>
                            <span className='profileText'>{   
                                sessionStorage.getItem('name') &&
                                sessionStorage.getItem('name').charAt(0).toUpperCase() + 
                                sessionStorage.getItem('name').slice(1)
                            }</span>
                            </a>
                        </MenuItem>

                        <MenuItem 
                        className="menuLogoutOption"
                        >
                            <a href="" className="dropdown-item has-icon text-danger emailBtn">
                            <i class="fas fa-envelope" aria-hidden="true"></i>
                            <span className='profileText'>{   
                                
                                sessionStorage.getItem('email')
                            }</span>
                            </a>
                        </MenuItem>

                        <MenuItem onClick={handleLogClose}
                        className="menuLogoutOption"
                        >
                            <a href="" className="dropdown-item has-icon text-danger logBtn">
                                <i className="fas fa-sign-out-alt"></i> 
                                <span className='profileText'>Logout</span>
                            </a>
                        </MenuItem>

                        
                    </Menu>
                </li>
            </ul>
        </nav>
    )
}

export default TopBar