import "./App.css";
import Login from "./component/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./component/Landing";
import Listing_programs from "./component/Listing_programs";
import AddOrganisation from "./component/AddOrganisation";
import EditOrganisation from "./component/EditOrganisation";
import AddUser from "./component/AddUser";
import EditUser from "./component/EditUser";
import Listing_orgList from "./component/Listing_orgList";
import LandingUser from "./component/LandingUser";
import Retail from "./component/Retail";
import Benchmark from "./component/Benchmark";
import Aftersales from "./component/Aftersales";
import Register from "./component/UserAuth/Register";
import ViewOrgdetail from "./component/ViewOrgdetail";
import ViewUser from "./component/ViewUser";
import Forgot_password from "./component/Forgot_password";
import ResetPassword from "./component/ResetPassword";
import LandingAdmin from "./component/Admin/LandingAdmin";
import RetailAdmin from "./component/Admin/RetailAdmin";
import { useEffect, useState } from "react";
import SampleBenchmark from "./component/SampleBenchmark";
import SampleLandingUser from "./component/SampleLandingUser";
import Benchmarkcopy from "./component/Benchmarkcopy";
import SampleUserLanding from "./component/SampleUserLanding";

function App() {
  
  let [user ,setUser] = useState(false)

 
  
  // useEffect(() => {
  //   if(sessionStorage.getItem('loggedIn') == 'true'){
  //     setUser(true)
  // }
  //   console.log("session",)
  //   console.log(user)
  // },[])

  return (
    <div>
      <Router basename="/">
        <Routes>
          <Route exact path="/" element={<Landing />} />
          {/* <Route exact path="/landinguser/:id" element={<LandingUser showSearch={true}/>} /> */}
          <Route exact path="/landinguser" element={<LandingUser showSearch={true}/>} />

          <Route exact path="/login" element={<Login />} />          
          <Route exact path="/forgot_password" element={<Forgot_password />} />
          <Route exact path="/resetpassword" element={<ResetPassword />} />
          <Route
            exact
            path="/listing_programs"
            element={<Listing_programs show={true}/>}
          />
          <Route exact path="/listing_orglist" element={<Listing_orgList show={true}/>} />
          <Route exact path="/addorgnisation" element={<AddOrganisation show={true}/>} />
          <Route exact path="/editorganisation/:id" element={<EditOrganisation show={true}/>}/>
          <Route exact path="/viewdetailorg/:id" element={<ViewOrgdetail show={true}/>}/>
          <Route exact path="/viewdetailuser/:id" element={<ViewUser show={true}/>}/>          
          <Route exact path="/adduser" element={<AddUser showSearch={true}/>} />
          <Route exact path="/edituser/:id" element={<EditUser showSearch={true}/>} />
          <Route exact path="/retail2022/:uid/:sid/:subid" element={<Retail showSearch={true}/>} />
          {/* <Route exact path="/evstudy2022/:uid/:sid/:subid" element={<Benchmark showSearch={true}/>} /> */}
          <Route exact path="/submodule" element={<Benchmarkcopy showSearch={true}/>} />

          <Route exact path="/aftersales2022/:uid/:sid/:subid" element={<Aftersales showSearch={true}/>} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/viewuser/:id' element={<ViewUser />} />
          <Route exact path='/landingadmin' element={<LandingAdmin show={true}/>} />
          {/* <Route exact path="/retailadmin/:id" element={<RetailAdmin showSearch={true} active={true}/>} /> */}
          <Route exact path="/retailadmin" element={<RetailAdmin showSearch={true} active={true}/>} />
         
          {/* sample routes */}
          <Route exact path="/sampleBenchmark" element={<SampleBenchmark showSearch={true}/>} />
          <Route exact path="/samplelanding" element={<SampleLandingUser showSearch={true}/>} />
          {/* <Route exact path="/samplelanding/:id" element={<SampleUserLanding showSearch={true}/>} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
