import React, { useEffect, useState } from "react";
import "../assets/css/surveyCss.css";
import logo from "../assets/img/LOGO-UScale.png";
import { useParams, useNavigate } from "react-router-dom";
import brand from "../assets/RETAIL/BrandInterest.png";
import env from "react-dotenv";
import axios from "axios";
import TopBar from "./TopBar";
import imgUrl from "../assets/img/imgUrl";


function Aftersales({ showSearch }) {
    let params = useParams();
    let navigate = useNavigate()
    let [subIdArr, setIdArr] = useState([]);
    let [show, setShow] = useState(false);
    let [iframelink, setIframLink] = useState("");
    let [surveyId, setSurveyId] = useState("");
    let [subModData, setSubModData] = useState([]);
    let [showClass, setShowClass] = useState(false)
    let [showSurvey,setShowSurvey]= useState(false)
    let [showSubModule,setshowSubModule]= useState(false)
    let [showPadding,setShowPadding]= useState(false)

    useEffect(() => {
        if (sessionStorage.getItem('loggedIn') != 'true') {
            navigate('/login')
        }
        let Arr = params.subid.split(",");
        setIdArr(Arr);
        setSurveyId(params.sid);
        // console.log("params",params)
    }, []);

    const getSubModuleData = async (sid) => {
        let d = await axios.get(`${env.API_URL}/getrepolinks/${sid}`);
        setSubModData(d.data);
    };

    useEffect(() => {
        if (surveyId != "") {
            getSubModuleData(surveyId);
        }
    }, [surveyId]);

    const handleClick = (id) => {
        let linkFound = subModData.filter((obj) => {
            if (obj["survey_txn_id"] === id) return obj;
        });
        if (linkFound.length > 0) {
            setIframLink(linkFound[0]["reportlink"]);
        }

        setShow(true)
        setShowClass(true)
        // const element = document.getElementById("iFrameDiv");
        // element.scrollIntoView();
    };

    const childToParent = (val) => {
        setShow(val);
        setIframLink('')
        setshowSubModule(false)
        setShowPadding(false)
      }
    
    
      return (
        <div>
        <div id="app">
         <div className="landing_nav landing_nav_retail">
         <div className="logoNav">
            <img className='navLogoimg' src={logo} alt='logo'></img>
          </div>
          <div className="emptyDiv">
            
          </div>
          <TopBar
            p={params.uid} 
            iconDisplay={showSearch} 
            showSurvey={true} 
            showSubModule={showSubModule} 
            childToParent={childToParent}/>
         </div>
         <div className={showPadding ? "benchSection benchinner hidePadding" : "benchSection benchinner"}>
            <section className={showClass ? "bench-section subSectionTwo":"bench-section"}>
            <div class="p-3 surveyHeader" >
            
            </div>
            <div class="categorydiv" style={{display:show ? "none":"block"}}>
            <h4 class="ev-title px-5">EV AFTER-SALES STUDY 2022</h4>
            <div class="category px-5 pt-5">
              <div>
                <h6 class="cate-title mb-4">Please select a category...</h6>
              </div>
              <div class="modulesDiv d-flex justify-content-between benchUser">
                
                  
                   
                  <div className={!subIdArr.includes("21") ?"disableSubModule":""}>
                    <div
                      class="card bench-card bench-main-card"
                      onClick={() => {handleClick(21);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div class="p-4">
                        <img
                          src={imgUrl.afterScale2}
                          class="card-img-top bench-img bench-img"
                          alt="..."
                        />
                      </div>
                      <div class="card-body text-center pt-0 pb-0">
                        <h6 class="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                        Demographics and Sample Distribution
                        </h6>
                      </div>
                    </div>
                        
                      </div>    
                      <div className={!subIdArr.includes("22") ?"disableSubModule":""}>
                    <div
                      class="card bench-card bench-main-card"
                      onClick={() => {handleClick(22);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div class="p-4">
                        <img
                          src={imgUrl.afterScale3}
                          class="card-img-top bench-img bench-img"
                          alt="..."
                        />
                      </div>
                      <div class="card-body text-center pt-0 pb-0">
                        <h6 class="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Service-Hotline 
                        </h6>
                      </div>
                    </div>
                        
                      </div>   
                      <div className={!subIdArr.includes("23") ?"disableSubModule":""}>
                    <div
                      class="card bench-card bench-main-card"
                      onClick={() => {handleClick(23);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div class="p-4">
                        <img
                          src={imgUrl.afterScale4}
                          class="card-img-top bench-img bench-img"
                          alt="..."
                        />
                      </div>
                      <div class="card-body text-center pt-0 pb-0">
                        <h6 class="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Battery Checks
                        </h6>
                      </div>
                    </div>
                        
                      </div>   
                      <div className={!subIdArr.includes("24") ?"disableSubModule":""}>
                    <div
                      class="card bench-card bench-main-card"
                      onClick={() => {handleClick(24);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div class="p-4">
                        <img
                          src={imgUrl.afterScale5}
                          class="card-img-top bench-img bench-img"
                          alt="..."
                        />
                      </div>
                      <div class="card-body text-center pt-0 pb-0">
                        <h6 class="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                         Data Protection
                        </h6>
                      </div>
                    </div>
                        
                      </div>   
                      <div className={!subIdArr.includes("25") ?"disableSubModule":""}>
                    <div
                      class="card bench-card bench-main-card"
                      onClick={() => {handleClick(25);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div class="p-4">
                        <img
                          src={imgUrl.afterScale6}
                          class="card-img-top bench-img bench-img"
                          alt="..."
                        />
                      </div>
                      <div class="card-body text-center pt-0 pb-0">
                        <h6 class="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Software Updates
                        </h6>
                      </div>
                    </div>
                        
                      </div>   
                      <div className={!subIdArr.includes("26") ?"disableSubModule":""}>
                    <div
                      class="card bench-card bench-main-card"
                      onClick={() => {handleClick(26);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div class="p-4">
                        <img
                          src={imgUrl.afterScale7}
                          class="card-img-top bench-img bench-img"
                          alt="..."
                        />
                      </div>
                      <div class="card-body text-center pt-0 pb-0">
                        <h6 class="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Digital Services
                        </h6>
                      </div>
                    </div>
                        
                  </div>  
                  <div className={!subIdArr.includes("27") ?"disableSubModule":""}>
                    <div
                      class="card bench-card bench-main-card"
                      onClick={() => {handleClick(27);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div class="p-4">
                        <img
                          src={imgUrl.afterScale1}
                          class="card-img-top bench-img bench-img"
                          alt="..."
                        />
                      </div>
                      <div class="card-body text-center pt-0 pb-0">
                        <h6 class="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Workshop
                        </h6>
                      </div>
                    </div>
                        
                  </div>  
                 
              </div>
              {/*  */}
              </div>
            </div>
          <div class="iframContainer" style={{display:show ? "block":"none"}}>
              
              <div className="iframe-wrapper" id="iFrameDiv">
              {/* <button className="btn btn-dark closeButton" onClick={() => {setShow(false) ;setIframLink('');setShowClass(false)}}>Show Submodules</button> */}
                {iframelink != "" ? (
                  <iframe
                    src={iframelink}
                    height="200"
                    width="300"
                    title="Iframe Example"
                    frameBorder="0"
                  ></iframe>
                ) : (
                  ""
                )}
              </div>
              </div>
            </section>
         </div>
           
        </div>
        </div>
      );
   
}

export default Aftersales