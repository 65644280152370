import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import image1 from "../assets/img/unsplash/landing.svg";
import logo from "../assets/img/LOGO-UScale.png";
import bendchStury from "../assets/img/unsplash/landing/bench-study.JPG";
import emspImg from "../assets/img/unsplash/landing/emsp.jpg";
import publicStudy from "../assets/img/unsplash/landing/public-study.JPG";
import retailStudy from "../assets/img/unsplash/landing/retail-study.JPG";
import benchMarkStudy from "../assets/img/unsplash/landing/bench-study.JPG";
import afterSalesStudy from "../assets/img/unsplash/landing/after-sales.JPG";

// import elecVehicle from '../assets/img/unsplash/elec-vehicle.JPG'
import ele from "../assets/img/unsplash/landing/elec-vehicle.JPG";
import sales from "../assets/img/unsplash/landing/after-sales.JPG";
import axios from "axios";
import env from "react-dotenv";
import TopBar from "./TopBar";
import ls from 'localstorage-slim';
import Loader from "./Loader";

function LandingUser({showSearch,show}) {
  let [id, setID] = useState(0);
  let navigate = useNavigate();
  let [data, setData] = useState([]);
  let [allData, setAllData] = useState([])
  let params = useParams();
  let [showSurvey, setshowSurvey] = useState(false)
  const [showLoader, setShowLoader] = useState(false);
  

  useEffect(() => {
    if(sessionStorage.getItem('loggedIn') != 'true'){
      navigate('/login')
  }
    let uId = localStorage.getItem("userId");
    setID(uId);
  }, []);

  ls.config.encrypt = true;
  ls.config.secret = 85;
  let uid = 
  // used uid instead of params.id
  useEffect(() => {
    uid = ls.get('uid',{secret:85}).toString()
    allurveyData()
    // if (params.id) {
    //   console.log(params.id)
    //   dashboardData(params.id);
    //   allurveyData()
    // }
  }, []);
  
  useEffect(() => {
    if (allData && allData.length > 0 ) {
      // console.log(params.id)
        //  console.log("uid",uid)
      // dashboardData(params.id,allData); 
      // console.log("inside all data")
      uid = ls.get('uid',{secret:85}).toString()
      dashboardData(uid,allData);
    }
  }, [allData,uid]);

  const allurveyData = async () => {
    setShowLoader(true)
    // (`${env.API_URL}/getallsurvey`)
    let allsurData = await axios.get(`${env.API_URL}/getallsurvey`);
    // console.log("all data-",allsurData.data)
    if(allsurData.data){
      setShowLoader(false)
    }
    setAllData(allsurData.data)
  }


  const dashboardData = async (values, sData) => {
    let d = await axios.get(`${env.API_URL}/getdashboarddata/${values}`);
    // console.log(d.data);
    let obj = {};
    let objTwo = {};
    // let surIdArr = []
    
    sData.forEach((survey) => {
        if (!obj[survey.survey_id]) {
          obj[survey.survey_id] = {
          submodule: [],
          disable:true,
          surveyDetails:survey.survey_description,
          surveyName:survey.survey_name,
          surveyImage:survey.survey_image,
          surveyRedirectLink: survey.survey_redirect
        };
        }
        
        let isFound = d.data.filter((obj) => {
          if(obj.survey_id === survey.survey_id)
          return obj
        })
        // console.log(isFound)
        if(isFound && isFound.length > 0){
          obj[survey.survey_id]["disable"] = false
          isFound.forEach((element) => {
          obj[survey.survey_id]["submodule"].push(element.survey_txn_id);
      }); 
     }
        
    })

    setData(obj);
  };

  

  return (
    <div>
      <div id="app">
      {showLoader ? <div className="loaderWrapper"><Loader data={showLoader}/></div>:""}
      <div className="navbar-bg landing_nav"></div>
        <TopBar iconDisplay={showSearch}/>
        <div className="landuserDiv">
        <section class="section landing-section landing-section-user landingUser">
          <div class="row h-100 p-5 mt-0px">
            <div class="col-md-6 col-sm-12 m-auto">
              <img src={logo} alt="logo" class="w-50 mb-5 mt-2 uscaleLogo" />
              <h1 class="mb-4 main-heading">
                Welcome to UScale Customer Insights Platform!
              </h1>
              <h5 class="mb-4 para-content">
                We offer insights on all customer touch points of the eMobility
                Customer Journey
              </h5>
            </div>
            <div class="col-6 d-flex loguser-img">
              <img src={image1} class="loguser-inimg" />
            </div>
          </div>
        </section>

        <section class="studies-section" id="studiessection">
          <div class="p-5">
              <div class="row">
                    
                    { data &&
                      Object.keys(data).map(key => {
                        // console.log("here",key)
                        // console.log("datakey",data[key].submodule)
                        let subIds
                        // Here I am filtering the if duplicate values recieved in arrry to pass subIds
                        let arrFilter = data[key].submodule
                        // console.log("arrFilter",arrFilter)
                        let filterData = arrFilter.filter((item,
                          index) => arrFilter.indexOf(item) === index)
                        if(filterData){
                          subIds = filterData.join(",");
                        }
                        // console.log("arr",filterData)  

//  Previous code to store subIds if(data[key].submodule){
                        //        subIds = data[key].submodule.join(",");
                        //          console.log(subIds)
                        //         }
                        // if(filterData){
                        //   const index = filterData.indexOf(15);  // hardcoded remove 15 value from array need to remove this logic
                        //   if (index > -1) { // only splice array when item is found
                        //     filterData.splice(index, 1); // 2nd parameter means remove one item only
                        //   }
                        //   subIds = filterData.join(",");
                        //       // console.log("subIds",subIds) 
  
                        // }
                        
                        
                        let compName = data[key].surveyName.replace(/ /g, "").toLowerCase()
                        // console.log("detail",data[key].surveyDetails);
                        
                        return<>
                          <div class="mainDiv">
                              <article class="article">
                                <div class="article-header">
                                  <div className={data[key].disable ? "article-image disable":"article-image"}
                                  onClick={() =>
                                    { 
                                      // navigate(`/${compName}/${params.id}/${key}/${subIds}`);
                                      
                                      setshowSurvey(true);
                                      if(filterData.length > 0){
                                        // window.sessionStorage.setItem('subIds',JSON.stringify(filterData))
                                        window.localStorage.setItem('subIds',subIds)
                                        window.localStorage.setItem('sid',key)
                                        window.localStorage.setItem('surveyName',data[key].surveyName)
                                        // window.sessionStorage.setItem('key',key)
                                      }
                                      navigate('/submodule')
                                    }
                                  }
                                  >
                                    <img src={data[key].surveyImage} />
                                  </div>
                                  <div class="article-title">
                                  </div>
                                </div>
                                <div className="article-details">
                                  <p className={data[key].disable ? "para-content disable":"para-content"} >
                                    <b>
                                      {
                                    
                                      data[key].surveyDetails
                                    }</b>
                                  </p>                          
                                  <div class="article-cta">
                                  <a href={data[key].surveyRedirectLink} target="_blank">Read More</a>
                                  </div>
                                </div>
                              </article>
                            </div>
                        </>
                    })
                  }
               
            </div>
          </div>
        </section>
        </div>
      
      </div>
    </div>
  );
}

export default LandingUser;
