import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Img from "../assets/img/myImg/LOGO-UScale.png";
import avatar1 from "../assets/img/avatar/avatar-1.png";
import { default as ReactSelect } from "react-select";
import { useNavigate } from "react-router-dom";
import env from "react-dotenv";
import { components } from "react-select";
import TopBar from "./TopBar";
import Sidebar from "./Sidebar";

function AddUser({show}) {
  let [data, setData] = useState([]);
  let [dataSur, setSurData] = useState([]);
  const [showSelectedSurvey, setShowSelectedSurvey] = useState([]);
  let [dataSubSurvey, setSubSurData] = useState([]);
  let [selectedSubSurveyID, setSelectedSubSurveyID] = useState([]);
  const [finalData, setFinalData] = useState({});
  let [value, setValue] = useState("");
  let [label, setLabel] = useState("");
  let [seclabel, setSecLabel] = useState("");
  let [thirdlabel, setThirdLabel] = useState("");
  let [userId, setUserId] = useState("");
  let [userName, setUserName] = useState("");
  let [emailid, setEmailid] = useState("");
  let [password, setPassword] = useState("");
  let [valSubSurvey, setValSubSurvey] = useState("");
  let [dropdownData, setDropdownData] = useState("");
  let [valSurvey, setValSurvey] = useState("");
  let [txnId, setTxnId] = useState();
  let [surId, setSurveyId] = useState();
  let [selectarr, setSelectArr] = useState({ label: "Select all", value: "*" });
  let navigate = useNavigate();
  const CryptoJS = require("crypto-js");
  const getSelectedSurvey = async (sSur, type) => {
    // console.log(sSur);
    setShowSelectedSurvey(sSur);
    setSelectedSubSurveyID([]);
    let mainArr = [];
    let indResp = 0;
    setSubSurData([]);
    if (sSur.length > 0) {
      let name = sSur.length === 1 ? false : true;
      sSur.forEach(async (sid) => {
        let dropdownData = await getSubSurveyData(
          sid["value"],
          sid["label"],
          name
        );
        if (dropdownData.length > 0) {
          indResp++;
          setSelectArr({ label: "Select all", value: "*" });
          mainArr = [...mainArr, ...dropdownData];
          if (indResp === sSur.length) {
            // console.log("mainArr", mainArr);
            setSubSurData(mainArr);
            setSelectedSubSurveyID(mainArr);
          }
        }
      });
      // if (mainArr.length > 0) {
      //   console.log(mainArr);
      //   setSubSurData(mainArr);
      // }
    } else {
      setSubSurData([]);
    }
  };
  const handleSelect = (e) => {
    // setValue(e)
    // to take the selected label from dropdown
    //console.log("event", e);
    setSurData([]);
    setShowSelectedSurvey([]);
    setSubSurData([]);
    setSelectedSubSurveyID([]);
    const label = e.substring(e.indexOf(",") + 1);
    const title = label.split(",")[0];
    //console.log("title", title);

    //const surveyId = label.substring(label.indexOf(",") + 1);
    // console.log("ID",surveyId)
    // to take the selected value from dropdown
    const val = e.split(",")[0];
    // console.log(val)
    setPassword("test@123"); // static value for backend user password handle
    setValue(val);
    setLabel(title);
    let obj = {
      Organization: title,
      org_id: val,
      Surveys: [],
    };
    setFinalData({ ...obj });
    //getSurveyOneData(2); //surveyId statically as not getting survey ids
    // console.log("org_id", val);
    asgnSurveyData(val);
    // getSurveyData("2,3");
    // getSurveyData(val);
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const asgnSurveyData = async (id) => {
    let d = await axios.get(`${env.API_URL}/getorg/${id}`);
    // console.log("SurveyListAssign:", d.data);

    let surveyArr = [];
    d.data.map((ele) => surveyArr.push(ele.survey_id));
    if (surveyArr.length > 0) {
      const str = String(surveyArr);

      // console.log(surveyArr);
      // for (let i = 0; i < surveyArr.length; i++) {
      //   let data = surveyArr[i];
      //   getSubSurveyData(data);
      // }
      // console.log(str);
      getSurveyData(str);
    } else {
      setSurData([]);
    }
  };

  // const handleSelectSurvey = (e) => {
  //   setValSurvey(e);
  //   //console.log("Survey ID:", e);
  //   const sublabel = e.substring(e.indexOf(",") + 1);
  //   const Idtransction = e.split(",")[0];
  //   setSurveyId(Idtransction);
  //   //console.log(sublabel);
  //   setSecLabel(sublabel);
  //   getSubSurveyData(sublabel);
  // };

  // const handleSelectSubSurvey = (e) => {
  //   setValSubSurvey(e);
  //   //console.log("third", e);
  //   const thirdTitle = e.substring(e.indexOf(",") + 1);
  //   // console.log("our",thirdTitle)
  //   const transactionId = e.split(",")[0];
  //   setTxnId(transactionId);
  //   // console.log("num",transactionId)
  //   setThirdLabel(thirdTitle);
  // };
  //   console.log(value);

  useEffect(() => {
    if(sessionStorage.getItem('loggedIn') != 'true'){
      navigate('/login')
  }
    getData();
    document.getElementsByClassName('sidebar-menu')[0].firstChild.classList.add("active")
    // getSurveyData();
    sideClass()
  }, []);

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  // console.log(isChecked)

  let getData = async () => {
    let d = await axios.get(`${env.API_URL}/getOrgList`);
    //console.log(d.data);
    // let arr = [];
    // d.data.forEach((ele) => {
    //   console.log(ele);
    //   let obj = {
    //     value: ele.org_id + "_2",
    //     label: ele.org_name,
    //   };
    //   arr.push(obj);
    // });
    setData(d.data);
  };
  const getSubSurvey = (selectedSubs) => {
    // console.log(selectedSubs);
    setSelectedSubSurveyID(selectedSubs);
  };
  let getSurveyData = async (sIds) => {
    let sidArr = sIds.split(",");
    let d = await axios.get(`${env.API_URL}/getsurveylist`);
    // console.log("SurveyList:", d.data);
    let newArr = [];

    //d.data.forEach((element) => {
    sidArr.forEach((sd) => {
      sd = sd * 1;
      let foundData = d.data.filter((obj) => {
        if (obj["survey_id"] === sd) return obj;
      });
      if (foundData && foundData.length) {
        let obj = {
          label: foundData[0].survey_name,
          value: foundData[0].survey_id,
        };
        newArr.push(obj);
      }
    });
    //filteration required as per logic
    //});
    setSurData([...newArr]);
  };
  // get SurveyList as per org
  //   let getSurveyOneData = async (id) => {
  //     let d = await axios.get(`${env.API_URL}/getsurveyonelist/` + id);
  //     console.log("SurveyList:", d.data);
  //     let arr = [];
  //     d.data.forEach((ele) => {
  //       let obj = {
  //         value: ele.survey_id,
  //         label: ele.survey_name,
  //       };
  //       arr.push(obj);
  //     });
  //     setSurData([...arr]);
  //   };

  let getSubSurveyData = async (id, survey_name, flag) => {
    try {
      //console.log("ID: ", id);
      let d = await axios.get(`${env.API_URL}/getsubsurveylist/${id}`);
      console.log("SubSurvey: ", d.data);
      let arr = [];
      d.data.forEach((ele) => {
        let obj = {
          value: id + "_" + ele.survey_txn_id,
          label: flag === true ? survey_name + "_" + ele.tabname : ele.tabname,
        };
        arr.push(obj);
      });
      return arr;
      //setSubSurData([...arr]);
    } catch (err) {
      return [];
      //console.log("SubSurveyErr: ", err);
    }
  };

  let handleSave = async (e) => {
    e.preventDefault();
    try {
      let surids = showSelectedSurvey.map((obj) => obj["value"]);
      let txnIds = selectedSubSurveyID.map((obj) => obj["value"]);

      // console.log(surids)
      // console.log(txnIds)
      let data = password;
      console.log("passoword",password)
      // Encrypt
      let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'my-secret-key@123').toString();
      //log encrypted data
      // console.log('Encrypt Data -')
      // console.log("ciphertext",ciphertext);
      
      let res = await axios
        .post(`${env.API_URL}/adduser`, {
          // "user_id": userId,
          user_name: userName,
          emailid: emailid,
          initialPass: ciphertext,  // need to check while inserting the same
          org_id: value,
          permission: isChecked ? "Y" : "N",
          survey_txnId: txnIds,
          survey_id: surids,
          created_by:sessionStorage.getItem('name'),
          modified_by:sessionStorage.getItem('name'),
        })
        .then((res) => {

          if (res.data.status === "FAIL") {
            alert(res.data.message);
          } else {
            // alert(res.data.message);
            navigate("/listing_programs");
          }
        });
    } catch (error) {
      // console.log(error);
    }

// email send
    try {
      let res = await axios
      .post(`${env.API_URL}/sendEmail`, {
        emailid: emailid,
        userName:userName,
        pswd:password
      })
      .then((res) => {
        // console.log('Email res: ',res)
        if (res.data.status === "FAIL") {
          // console.log('Email status: ',res.data.message);
        } else {
          // console.log('Email status2: ',res);
          // alert(res.data.message);
          //navigate("/listing_programs");
        }
      });
  } catch (error) {
    console.log(error);
  }
    navigate("/listing_programs");
  };

  let handleKeyUp = (e) => {

    let emlid = emailid;
    // var mailformat = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/;
    var mailformat="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$";
    if(emlid.match(mailformat))
    {
    // alert("You have entered a valid email address!");    //The pop up alert for a valid email address
    //document.getElementById("email").focus();
    return true;
    }
    else
    {
    alert("You have entered an invalid email address!");    //The pop up alert for an invalid email address
    //document.getElementById("email").focus();
    return false;
    }
   }

    let sideClass = () => {
            
      if (document.body.classList.contains('sidebar-show')) {
          // console.log("doc",document.body.classList)
          document.body.classList.remove('sidebar-show');
          document.body.classList.add('sidebar-mini');
      } 
      else if (document.body.classList.contains('sidebar-mini')) {
          // document.body.classList.remove('sidebar-mini');
          document.body.classList.add('sidebar-mini');
      }
      else if (window.innerWidth < 767){
          document.body.classList.remove('sidebar-mini')
          // console.log("doc",document.body.classList)
          document.body.classList.remove('sidebar-show')
      }

    }

  //   console.log(dataSur);
  return (
    <div>
      <div id="app">
        <div className="main-wrapper">
          <div className="navbar-bg landing_nav"></div>
          <TopBar/>
          <Sidebar adminActive={show}/>
          {/*   <!-- Main Content -->*/}

          <div className="main-content" onClick={sideClass}>
            <section className="section">
              <div className="section-header">
                <h1>Dashboard</h1>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="text-black-50">Add User</h4>
                      <div
                        className="card-header-action"
                        onClick={() => navigate("/listing_programs")}
                      >
                        <a href="" className="btn btn-primary">
                          View All Users
                        </a>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <Form className="formContent">
                        {/* <Form.Group className="mb-3" controlId="formBasicName">
                                                    <Form.Label>User Id</Form.Label>
                                                    <Form.Control
                                                        onChange={(e) => setUserId(e.target.value)}
                                                        type="number" placeholder="User Id" name="userId" />
                                                </Form.Group>
                                            */}
                        <div className="divFlex">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                              onChange={(e) => setUserName(e.target.value)}
                              type="text"
                              placeholder="User name"
                              name="userName"
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>User Email</Form.Label>
                            <Form.Control
                              onChange={(e) => setEmailid(e.target.value)}
                              onBlur={handleKeyUp}
                              type="text"
                              placeholder="User email"
                              name="emailid"
                            />
                          </Form.Group>
                        </div>
                        {/* 
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>User Password</Form.Label>
                          <Form.Control
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            placeholder="User name"
                            name="password"
                          />
                        </Form.Group> */}

                        {/* <Form.Group className="mb-3" controlId="formBasicName">
                                                
                                                <Dropdown style={{marginBottom:"15px"}}>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                        Assign Organisation
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu onSelect={(e) => setDropdownData(e.target.value)}>
                                                        {
                                                            data && data.map( (item, i) => {
                                                                return <>
                                                                    <Dropdown.Item href="" value={item.org_id}>{item.org_name}</Dropdown.Item>
                                                                </>
                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                </Form.Group> */}

                        <div className="dropDownblock">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>Organisation</Form.Label>
                            <DropdownButton class="dropdown-item dropdownMenu"
                              style={{ marginBottom: "15px", width: "40%" }}
                              className="alignRight"
                              title={label != "" ? label : "--Select--"}
                              id="dropdown-menu-align-right"
                              onSelect={handleSelect}
                            >
                              {data &&
                                data.map((item, i) => {
                                  return (
                                    <div key={i}>
                                      <Dropdown.Item
                                        eventKey={[
                                          item.org_id,
                                          item.org_name,
                                          item.Survey_Id,
                                        ]}
                                      >
                                        {item.org_name}
                                      </Dropdown.Item>
                                    </div>
                                  );
                                })}
                            </DropdownButton>
                            {/* <ReactSelect
                              isMulti
                              allowSelectAll={true}
                              options={data}
                              onChange={getSelectedOrg}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                            /> */}
                          </Form.Group>
                        </div>
                        <div className="">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>Survey Dashboard</Form.Label>
                            {/* <DropdownButton
                              style={{ marginBottom: "15px" }}
                              alignRight
                              title={seclabel != "" ? seclabel : "--Select--"}
                              id="dropdown-menu-align-right"
                              onSelect={handleSelectSurvey}
                            >
                              {dataSur &&
                                dataSur.map((item, i) => {
                                  return (
                                    <>
                                      {console.log(item.survey_id)}
                                      <Dropdown.Item
                                        eventKey={[
                                          item.survey_id,
                                          item.survey_name,
                                        ]}
                                      >
                                        {item.survey_name}
                                      </Dropdown.Item>
                                    </>
                                  );
                                })}
                            </DropdownButton> */}
                            <ReactSelect
                              isMulti
                              components={{ Option, MultiValue }}
                              allowSelectAll={true}
                              options={[selectarr, ...dataSur]}
                              // onChange={getSelectedSurvey}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={showSelectedSurvey}
                              onChange={(selected) => {
                                if (
                                  selected !== null &&
                                  selected.length > 0 &&
                                  selected[selected.length - 1].value ===
                                    selectarr.value
                                ) {
                                  return getSelectedSurvey(dataSur, "all");
                                }
                                return getSelectedSurvey(selected, "single");
                              }}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>Sub Module</Form.Label>
                            <ReactSelect
                              isMulti
                              components={{ Option, MultiValue }}
                              allowSelectAll={true}
                              options={[selectarr, ...dataSubSurvey]}
                              // onChange={getSubSurvey}
                              onChange={(selected) => {
                                if (
                                  selected !== null &&
                                  selected.length > 0 &&
                                  selected[selected.length - 1].value ===
                                    selectarr.value
                                ) {
                                  return getSubSurvey(dataSubSurvey);
                                }
                                return getSubSurvey(selected);
                              }}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={selectedSubSurveyID}
                            />
                            {/* <DropdownButton
                              style={{ marginBottom: "15px" }}
                              className="alignRight menuItem"
                              title={
                                thirdlabel != "" ? thirdlabel : "--Select--"
                              }
                              id="dropdown-menu-align-right"
                              onSelect={handleSelectSubSurvey}
                            >
                              {dataSubSurvey &&
                                dataSubSurvey.map((item, i) => {
                                  return (
                                    <div key={i}>
                                      <Dropdown.Item
                                        // className='menuItem'
                                        eventKey={[
                                          item.survey_txn_id,
                                          item.tabname,
                                        ]}
                                      >
                                        {item.tabname}
                                      </Dropdown.Item>
                                    </div>
                                  );
                                })}
                            </DropdownButton> */}
                          </Form.Group>
                        </div>

                        {/* <Form.Group className="mb-3" controlId="formBasicName">
                          <div>
                            <input
                              type="checkbox"
                              id="topping"
                              name="topping"
                              value="Paneer"
                              checked={isChecked}
                              onChange={handleOnChange}
                            />
                            Grant Permission
                          </div>
                        </Form.Group> */}

                        <Button variant="primary" onClick={handleSave}>
                          Submit
                        </Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <footer className="main-footer">
            {/* 
            <!-- <div className="footer-left">
              Copyright &copy; 2018 <div className="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval
                Azhar</a>
            </div>
            <div className="footer-right">
              2.3.0
            </div> -->
        */}
          </footer>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
