import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Img from "../assets/img/myImg/LOGO-UScale.png";
import avatar1 from "../assets/img/avatar/avatar-1.png";
import imageUrl from "../assets/img/imageUrl";
import env from "react-dotenv";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import { dangerMessage } from '../component/handler/errorhandler'


function Listing_programs({show}) {
  // console.log(`${env.API_URL}`);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [name, setName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  let navigate = useNavigate();

  useEffect(() => {
    if(sessionStorage.getItem('loggedIn') != 'true'){
        navigate('/login')
    }
    document.body.classList.add('bg-grey');
    setName(localStorage.getItem("name"));
    getData();
    sideClass();
  }, []);

  // useEffect(() => {
  //   getData()
  // },[count])

  let getData = async () => {
    let d = await axios.get(`${env.API_URL}/getorguserlist`);
    // console.log(d.data);
    d.data.sort(function (a, b) {
      if (a.org_name < b.org_name) {
        return -1;
      }
      if (a.org_name > b.org_name) {
        return 1;
      }
      return 0;
    })
    setData(d.data);
  };

  // let handleDelete = async (id) => {

  //   console.log("deleted response1");
  //   try {
  //     let res = await axios.delete(`${env.API_URL}/deleteuser/` + id);
  //     setCount(count + 1);
  //   } catch (err) {
  //     console.log("deleted response3");
  //     console.log(err);
  //   }
  //   getData();
  // };

  let handleDelete = async (id) => {
    // console.log("deleted response1");
    dangerMessage("Are you sure you want to delete?", "", async () => {
      try {
        let res = await axios.delete(`${env.API_URL}/deleteuser/` + id);
        setCount(count + 1);
        getData();
      } catch (err) {
        // console.log("deleted response3");
        // console.log(err);
      }
      getData();
    }, () => { console.log("false") }, false);
  };

  let sideClass = () => {
        
    if (document.body.classList.contains('sidebar-show')) {
        // console.log("doc",document.body.classList)
        document.body.classList.remove('sidebar-show');
        document.body.classList.add('sidebar-mini');
    } 
    else if (document.body.classList.contains('sidebar-mini')) {
        // document.body.classList.remove('sidebar-mini');
        document.body.classList.add('sidebar-mini');
    }
    else if (window.innerWidth < 767){
        document.body.classList.remove('sidebar-mini')
        // console.log("doc",document.body.classList)
        document.body.classList.remove('sidebar-show')
    }

}


  return (
    <div>
      <div id="app">
        <div className="main-wrapper">
          <div className="navbar-bg landing_nav"></div>

          <TopBar />
          <Sidebar adminActive={show}/>

          {/*   <!-- Main Content -->*/}

          <div className="main-content" onClick={sideClass}>
            <section className="section">
              <div className="section-header">
                <h1>Dashboard</h1>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="text-black-50">Lastest Programs</h4>
                      <div
                        className="card-header-action"
                        onClick={() => navigate("/addorgnisation")}
                      >
                        <a href="" className="btn btn-primary">
                          Add Organisation
                        </a>
                      </div>
                      <div
                        className="card-header-action userBtnlist"
                        onClick={() => navigate("/listing_orglist")}
                      >
                        <a href="" className="btn btn-primary">
                          View All Organisation
                        </a>
                      </div>
                      <div
                        className="card-header-action userBtnlist"
                        onClick={() => navigate("/adduser")}
                      >
                        <a
                          href=""
                          style={{ fontSize: "15px" }}
                          className="btn btn-primary"
                        >
                          Add User
                        </a>
                      </div>
                    </div>
                    <div className="card-body p-0 formContent adminboard">
                      <div className="table-responsive">
                        <table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "30%" }}>User</th>
                              <th style={{ width: "30%" }}>Email ID</th>
                              <th style={{ width: "20%" }}>Organisation</th>
                              <th style={{ width: "20%", textAlign: "center" }}>
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      style={{
                                        width: "30%",
                                        cursor: "pointer",
                                      }}
                                    // onClick={() =>
                                    //   navigate("/edituser/" + item.user_id)
                                    // }
                                    >
                                      {item.user_name.charAt(0).toUpperCase()+item.user_name.slice(1)}
                                    </td>

                                    <td
                                      style={{
                                        width: "30%",
                                        cursor: "pointer",
                                      }}
                                    // onClick={() =>
                                    //   navigate("/edituser/" + item.user_id)
                                    // }
                                    >
                                      {item.emailid}
                                    </td>
                                    <td
                                      style={{ width: "20%" }}
                                    // onClick={() =>
                                    //   navigate(
                                    //     "/editorganisation/" + item.org_id
                                    //   )
                                    // }
                                    >
                                      {item.org_name}
                                    </td>
                                    <td
                                      style={{
                                        width: "20%",
                                        textAlign: "center",
                                      }}
                                      className="formlastTable"
                                    >
                                      <a className="btn btn-edit  btn-action mr-1" data-toggle="tooltip" title="Edit"
                                      onClick={() =>
                                        navigate("/edituser/" + item.user_id)
                                      }
                                      >
                                        <i className="fas fa-pencil-alt"
                                         
                                        ></i></a>
                                      <a
                                        className="btn btn-view  btn-action mr-1"
                                        data-toggle="tooltip"
                                        title="View"
                                        onClick={() =>
                                          navigate(`/viewuser/${item.user_id}`)
                                        }
                                      >
                                        <i className="fas fa-file-alt"></i>
                                      </a>
                                      <a
                                        className="btn btn-delete btn-action"
                                        onClick={() =>
                                          handleDelete(item.user_id)
                                        }
                                        data-toggle="tooltip"
                                        title="Delete"
                                        data-confirm="Are You Sure?|This action can not be undone. Do you want to continue?"
                                        data-confirm-yes="alert('Deleted')"
                                      >
                                        <i className="fas fa-trash"></i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <footer className="main-footer">
            {/* 
            <!-- <div className="footer-left">
              Copyright &copy; 2018 <div className="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval
                Azhar</a>
            </div>
            <div className="footer-right">
              2.3.0
            </div> -->
        */}
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Listing_programs;
