import React, { useEffect, useState } from "react";
import imageUrl from "../assets/RETAIL/imageUrl";
import "../assets/css/surveyCss.css";
import logo from "../assets/img/LOGO-UScale.png";
import { useParams, useNavigate } from "react-router-dom";
import brand from "../assets/RETAIL/BrandInterest.png";
import website from "../assets/RETAIL/WebsiteVisits.png";
import axios from "axios";
import dealer from "../assets/RETAIL/DealerVisits.png";
import drives from "../assets/RETAIL/testdrives.png";
import finance from "../assets/RETAIL/finance.png";
import delivery from "../assets/RETAIL/delivery.png";
import followup from "../assets/RETAIL/followup.png";
import env from "react-dotenv";
import TopBar from "./TopBar";


function Retail({showSearch}) {

  let params = useParams();
  let navigate = useNavigate()
 
  let [subIdArr, setIdArr] = useState([]);
  let [iframelink, setIframLink] = useState("");
  let [show, setShow] = useState(false);
  let [surveyId, setSurveyId] = useState("");
  let [subModData, setSubModData] = useState([]);
  let [showClass, setShowClass] = useState(false)
  let [showSurvey,setShowSurvey]= useState(false)
  let [showSubModule,setshowSubModule]= useState(false)
  let [showPadding,setShowPadding]= useState(false)

  useEffect(() => {
    if(sessionStorage.getItem('loggedIn') != 'true'){
      navigate('/login')
  }
    let Arr = params.subid.split(",");
    setIdArr(Arr);
    setSurveyId(params.sid);
    // console.log(env)
    // console.log("params",params)
  }, []);

  const getSubModuleData = async (sid) => {
    let d = await axios.get(`${env.API_URL}/getrepolinks/${sid}`);
    setSubModData(d.data);
  };

  useEffect(() => {
    if (surveyId != "") {
      getSubModuleData(surveyId);
    }
  }, [surveyId]);

  const handleClick = (id) => {
    setIframLink('');
    let linkFound = subModData.filter((obj) => {
      if (obj["survey_txn_id"] === id) return obj;
    });
    if (linkFound.length > 0) {
      setIframLink(linkFound[0]["reportlink"]);
    }
    setShow(true)
    setShowClass(true)
    // const element = document.getElementById("iFrameDiv");
    // element.scrollIntoView();
  };

  const childToParent = (val) => {
    setShow(val);
    setIframLink('')
    setshowSubModule(false)
    setShowPadding(false)
  }

  return (
    <div>
      <div id="app">
      <div className="landing_nav landing_nav_retail">
      <div className="logoNav">
        <img className='navLogoimg' src={logo} alt='logo'></img>
      </div>
      <div className="emptyDiv">
        
      </div>
        <TopBar
        p={params.uid} 
        iconDisplay={showSearch} 
        showSurvey={true} 
        showSubModule={showSubModule} 
        childToParent={childToParent}/>

      </div>
      <div className={showPadding ? "retailSection benchinner hidePadding" : "retailSection benchinner"}>
      <section className={showClass ? "retail-section subSectionTwo":"retail-section"}>
          <div className="p-3 surveyHeader">
            {/* <i class="fa fa-home" aria-hidden="true" 
            // onClick={()=> navigate(`/landinguser/${params.uid}`)}
            ></i> */}
            {/* <button className="btn btn-dark"
            onClick={()=> navigate(`/landinguser/${params.uid}`)}
            >Show Surveys
            </button> */}
          </div>

          <div className="categorydiv" style={{display:show ? "none":"block"}}>
          <h4 class="ev-title px-5">EV RETAIL STUDY 2022</h4>
            <div className="category px-5 pt-5">
              <div>
                <h6 className="retail-title mb-4">Please select a category...</h6>
              </div>
              <div className="modulesDiv d-flex justify-content-between retailUser">
                {/* <div className="card-container"> */}
                
                  <div className={!subIdArr.includes("8") ?"disableSubModule":""}>
                    <div
                      className="card retail-card main-card"
                      onClick={() => {handleClick(8);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div className="p-4">
                        <img
                          src={brand}
                          className="card-img-top car-img "
                          alt="..."
                        />
                      </div>
                      <div className="card-body text-center pt-0 pb-0">
                        <h6 className="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Brand Interest and Info Phase
                        </h6>
                      </div>
                    </div>
                  </div>
              
                
                  <div className={!subIdArr.includes("9") ?"disableSubModule":""}>
                    <div
                      className="card retail-card main-card"
                      onClick={() => {handleClick(9);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div className="p-4">
                        <img
                          src={website}
                          className="card-img-top car-img"
                          alt="..."
                        />
                      </div>
                      <div className="card-body text-center pt-0 pb-0">
                        <h6 className="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Website Visits(Brand & Dealer)
                        </h6>
                      </div>
                    </div>
                  </div>
                
                
                  <div className={!subIdArr.includes("10") ?"disableSubModule":""}>
                    <div
                      className="card retail-card main-card"
                      onClick={() => {handleClick(10);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div className="p-4">
                        <img
                          src={dealer}
                          className="card-img-top car-img"
                          alt="..."
                        />
                      </div>
  
                      <div className="card-body text-center pt-0 pb-0">
                        <h6 className="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Dealer Visits & Events
                        </h6>
                      </div>
                    </div>
                  </div>
                
  
                
                  <div className={!subIdArr.includes("11") ?"disableSubModule":""}>
                    <div
                      className="card retail-card main-card"
                      onClick={() => {handleClick(11);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div className="p-4">
                        <img
                          src={drives}
                          className="card-img-top car-img"
                          alt="..."
                        />
                      </div>
  
                      <div className="card-body text-center pt-0 pb-0">
                        <h6 className="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Test Drives & Configuration
                        </h6>
                      </div>
                    </div>
                  </div>
                
  
                
                  <div className={!subIdArr.includes("12") ?"disableSubModule":""}>
                    <div
                      className="card retail-card main-card"
                      onClick={() => {handleClick(12);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div className="p-4">
                        <img
                          src={finance}
                          className="card-img-top car-img"
                          alt="..."
                        />
                      </div>
  
                      <div className="card-body text-center pt-0 pb-0">
                        <h6 className="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Finance & Insurance
                        </h6>
                      </div>
                    </div>
                  </div>
                
                
                  <div className={!subIdArr.includes("13") ?"disableSubModule":""}>
                    <div
                      className="card retail-card main-card"
                      onClick={() => {handleClick(13);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div className="p-4">
                        <img
                          src={delivery}
                          className="card-img-top car-img"
                          alt="..."
                        />
                      </div>
  
                      <div className="card-body text-center pt-0 pb-0">
                        <h6 className="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Vehicle Delivery & Follow-up
                        </h6>
                      </div>
                    </div>
                  </div>
                
                
                  <div className={!subIdArr.includes("14") ?"disableSubModule":""}>
                    <div
                      className="card retail-card main-card"
                      onClick={() => {handleClick(14);setshowSubModule(true);setShowPadding(true)}}
                    >
                      <div className="p-4">
                        <img
                          src={followup}
                          className="card-img-top car-img"
                          alt="..."
                        />
                      </div>
  
                      <div className="card-body text-center pt-0 pb-0">
                        <h6 className="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                          Demographics & Sample Distribution
                        </h6>
                      </div>
                    </div>
                  </div>
                
              </div>
            </div>
            </div>
          <div className="iframContainer" style={{display:show ? "block":"none"}}>
          <div className="iframe-wrapper" id="iFrameDiv">
          {/* <i class="fa fa-arrow-left closeButton" aria-hidden="false"
          // style={{fontSize:"30px"}}
          // onClick={() => {setShow(false) ;setIframLink('');setShowClass(false)}}
          ></i> */}
          {/* <button className="btn btn-dark closeButton" onClick={() => {setShow(false) ;setIframLink('');setShowClass(false)}}>Show Submodules</button> */}
          {iframelink != "" ? (
            <iframe
              src={iframelink}
              height="200"
              width="300"
              title="Iframe Example"
              frameBorder="0"
            ></iframe>
          ) : (
            ""
          )}
          </div>
          </div>
        </section>
      </div>
       
      </div>
    </div>
  );
}

export default Retail;
