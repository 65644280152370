import React, { useState } from 'react'
import Img from "../assets/img/myImg/LOGO-UScale.png";
import { Link, useNavigate } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';



function Sidebar({ active, adminActive }) {
  let navigate = useNavigate()
  let [firstList, setFirstList] = useState(false)
  let [secondList, setSecondList] = useState(false)
  let [show, setShow] = useState(true)

  let handleFirst = () => {
    setShow(false)
  }

  let handleSecond = () => {
    setFirstList(false)
    setSecondList(true)
  }

  return (
    <div className="main-sidebar sidebar-style-2">
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand">
          <a href="#">
            <img src={Img} className="w-50" />
          </a>
        </div>
        <div className="sidebar-brand sidebar-brand-sm">
          <a href="#">
            <img src={Img} className="w-75" />
          </a>
        </div>
        <ul className="sidebar-menu">
          <li className={adminActive ? "nav-item dropdown active" : "nav-item "}
            onClick={handleFirst}

          >

            <a class="nav-link"><i class="fas fa-user" aria-hidden="true" onClick={() => navigate('/listing_programs')}></i><span>
              <Link to='/listing_programs' >
                User Management
              </Link>
            </span></a>

          </li>

          <li className={active ? "nav-item dropdown active" : "nav-item "}
          // onClick={handleSecond}
          >
            <a class="nav-link"><i class="fas fa-desktop" aria-hidden="true" onClick={() => navigate('/landingadmin')}></i><span>
              <Link to='/landingadmin'>Admin Dashboard</Link>
            </span></a>

          </li>
        </ul>
      </aside>
    </div>
  )
}

export default Sidebar