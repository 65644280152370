import React, { useEffect, useState } from "react";
import logo from "../assets/img/LOGO-UScale.png";
import "../assets/css/surveyCss.css";
import { useParams, useNavigate } from "react-router-dom";
import env from "react-dotenv";
import axios from "axios";
import driving from "../assets/Benchmark/Driving7963300015927257.png";
import diplay from "../assets/Benchmark/Display01677025792450637.png";
import naving from "../assets/Benchmark/Naving2868597297686222.png";
import AIr from "../assets/Benchmark/AIr578545480976038.png";
import charging from "../assets/Benchmark/Charging5849141785961023.png";
import connect from "../assets/Benchmark/Connect05760734071395901.png";
import Bild from "../assets/Benchmark/Bild27371396642081265.png";
import TopBar from "./TopBar";
import ls from 'localstorage-slim';
import Loader from "./Loader";
import { Container, Grid } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import SampleTopBar from "./SampleTopBar";


function SampleBenchmark({showSearch}) {
  let params = useParams();
  let navigate = useNavigate()
  let [subIdArr, setIdArr] = useState([]);
  let [iframelink, setIframLink] = useState("");
  let [show, setShow] = useState(false);
  let [surveyId, setSurveyId] = useState("");
  let [subModData, setSubModData] = useState([]);
  let [subAllModData, setAllSubModData] = useState([]);
  let [showClass, setShowClass] = useState(false)
  let [showSurvey,setShowSurvey]= useState(false)
  let [showSubModule,setshowSubModule]= useState(false)
  let [showPadding,setShowPadding]= useState(false)
  let [disableArr,setDisableArr] = useState([])
  const [showLoader, setShowLoader] = useState(false);

  // 20-09-2022
  // let [langTab,setLangTab] = useState([])
  // let [isMultiTab, setIsmultiTab] = useState(false)

  ls.config.encrypt = true;
  ls.config.secret = 85;

  const getSubModuleData = async (sid) => {
    let d = await axios.get(`${env.API_URL}/getrepolinks/${sid}`);
    setSubModData(d.data);
  };

  const getallSubModuleData = async (sid) => {
    setShowLoader(true)
    let d = await axios.get(`${env.API_URL}/getallrepolinks/${sid}`);
    if(d.data){
      setShowLoader(false)
    }
    // setAllSubModData(d.data);
    setAllSubModData(d.data.sort((a, b) => a.survey_txn_id  - b.survey_txn_id));
    // console.log("all data",d.data)
  };

  

  // 20-09-2022
  // const getMultiTabLink = async () => {
  //   let d = await axios.get(`http://localhost:3004/uscale_server/getmultilangtab`);
  //   let Arr = [] 
  //   d.data.map((item) => {
  //       Arr.push(item.survey_id)
  //   })
  //   console.log(Arr)
  //   setLangTab(Arr);
  // }


 

  useEffect(() => {
  //   if(sessionStorage.getItem('loggedIn') != 'true'){
  //     navigate('/login')
  // }

    if (surveyId != "" && surveyId!=undefined) {
      getSubModuleData(surveyId);
      getallSubModuleData(surveyId)
      // 20-09-2022
      // console.log("surveyID",surveyId)
      // console.log(langTab.some((e) => e == surveyId))
      // setIsmultiTab(langTab.some((e) => e == surveyId))

    }
  }, [surveyId]);
  
  useEffect(() => {
    // let allSubIds = ls.get('subIds', { secret: 85 })
    let allSubIds = localStorage.getItem('subIds')
    setDisableArr(allSubIds)
    let uid = ls.get('uid', { secret: 85 }).toString()
    let sid = localStorage.getItem('sid').toString()
    // ls.get('key', { secret: 85 }).toString()
    // console.log("allSubIds",allSubIds.split(','))
    let Arr = allSubIds.split(',')
    setIdArr(Arr);
    // console.log("sid",sid)
    
    setSurveyId(sid);
    
  }, [disableArr]);

  const handleClick = (id) => {
    setIframLink('');
    let linkFound = subModData.filter((obj) => {
      if (obj["survey_txn_id"] === id) return obj;
    });
    if (linkFound.length > 0) {
      setIframLink(linkFound[0]["reportlink"]);
    }
    setShow(true)
    setShowClass(true)

    // const element = document.getElementById("iFrameDiv");
    // element.scrollIntoView();
  };

  const childToParent = (val) => {
    setShow(val);
    setIframLink('')
    setshowSubModule(false)
    setShowPadding(false)
  
  }

  return (
    <div>
    <div id="app">
    {showLoader ? <div className="loaderWrapper"><Loader data={showLoader}/></div>:""}
     <div className="landing_nav landing_nav_retail">
     <div className="logoNav">
        <img className='navLogoimg' src={logo} alt='logo'></img>
      </div>

      <div className="emptyDiv">
        
      </div>
      <SampleTopBar
      showSurvey={true} 
      showSubModule={showSubModule} 
      childToParent={childToParent}
      />
     </div>
     <div className={showPadding ? "benchSection benchinner hidePadding" : "benchSection benchinner"}>
        <section className={showClass ? "bench-section subSectionTwo":"bench-section"}>
        <div class="p-3 surveyHeader" >
        
        </div>
        <div class="categorydiv" style={{display:show ? "none":"block"}}>
        <h4 class="ev-title px-5">{localStorage.getItem('surveyName').toString()}</h4>

        <div class="category pt-5">
          <div>
            <h6 class="cate-title mb-4">Please select a category...</h6>
          </div>
          <div class="modulesDiv d-flex justify-content-between benchUser">
            
              
              { subAllModData.length > 0 && subAllModData.map((item) => {
                  // let newArr = sessionStorage.getItem('subIds'))
                  // console.log("newArr",disableArr)
                  return <>
                  
                  <div className={!disableArr.includes(item.survey_txn_id) ?"disableSubModule":""}>
                  <div
                    class="card bench-card bench-main-card"
                    onClick={() => {handleClick(item.survey_txn_id);setshowSubModule(true);setShowPadding(true)}}
                  >
                    <div class="p-4">
                      <img
                        src={item.survey_submdule_image}
                        class="card-img-top bench-img bench-img"
                        alt="..."
                      />
                    </div>
                    <div class="card-body text-center pt-0 pb-0">
                      <h6 class="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                        {item.tabname.charAt(0).toUpperCase() + item.tabname.slice(1)}
                      </h6>
                    </div>
                  </div>
                </div>
                </>
              })}
            
          
          </div>
          {/*  */}
          </div>
        </div>
      <div class="iframContainer" style={{display:show ? "block":"none"}}>
          <div className="iframe-wrapper" id="iFrameDiv">
          {/* <button className="btn btn-dark closeButton" onClick={() => {setShow(false) ;setIframLink('');setShowClass(false)}}>Show Submodules</button> */}
            {iframelink != "" ? (
              <iframe
                src={iframelink}
                height="200"
                width="300"
                title="Iframe Example"
                frameBorder="0"
              ></iframe>
            ) : (
              ""
            )}
          </div>

          </div>
          <footer className="footer" style={{display:show ? "block":"none"}}>
           <Container maxWidth="lg">
            <Box px={3} py={1} display="flex" flexWrap="wrap" alignItems="right" justifyContent="right"
            style={{justifyContent:"end"}}>
            </Box>
          </Container>
            </footer>
        </section>
     </div>
    </div>
    </div>
  );
}

export default SampleBenchmark;
