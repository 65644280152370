import React, { useState, useEffect } from "react";
import "../assets/css/components.css";
import imgUrl from "../assets/img/imgUrl.js";
import apiBaseUrl from "../config";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import env from 'react-dotenv'
import ls from 'localstorage-slim';


function Login() {

  const [allUser, getAllUsers] = useState([]);
  const oldValueRef = React.useRef(0)
  const nameForm = React.useRef(null)
  const navigate = useNavigate()
  const CryptoJS = require("crypto-js");
  const [allValues, setAllValues] = useState({
    email: '',
    password: '',
    isChecked: false
 });

  const UserCall = () => {
    fetch(
      `${env.API_URL}/getUser`
      // "https://apps.duredemos.com/uscale_server/getUser"
    )
      .then((response) => response.json())
      .then((data) => {
        getAllUsers(data);
      })
      .catch((err) => {
        console.log(`${err} while contacting the quote API.`);
      });
  }

  const Login = () => {
    const data = {
      email: allValues.email,
      password: CryptoJS.AES.encrypt(JSON.stringify(allValues.password), 'my-secret-key@123').toString()
    }
    console.log(env.API_URL)
    let UserExist = false;
    // let url = `${env.API_URL}/login`
    fetch(`${env.API_URL}/login`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.token) {
        var decoded = jwt_decode(data.token);
        console.log(decoded)
        return decoded
        } return 'Incorrect Email or Password'
      }).then((decoded) => {
        if (decoded != 'Incorrect Email or Password') {
        sessionStorage.setItem('name',decoded.user.user_name)
        sessionStorage.setItem('loggedIn','true')
        sessionStorage.setItem('email',decoded.user.emailid)
        ls.set('uid',decoded.user.user_id)
        if (decoded.user.usertype == "1") {
          navigate('/landingadmin')
        } else {
          
          navigate('/landinguser')
        }
    } else {
      
        var msg1 = document.getElementById("valMsg1");
        var msg2 = document.getElementById("valMsg2");
        var msg3 = document.getElementById("valMsg3");

          msg3.style.display = "block";
          msg1.style.display = "none";
          msg2.style.display = "none";
        }
        // msg 'email and password wrong please enter the right credentials'
        // if (!boolemail) {

        //   msg1.style.display = "block";
        //   msg2.style.display = "none";
        //   msg3.style.display = "none";
        // }
        // // msg 'email id is wrong please enter right email'
        // if (!boolpswd) {

        //   msg2.style.display = "block";
        //   msg1.style.display = "none";
        //   msg3.style.display = "none";
        // }
        // msg 'password is wrong please enter right password'
           
    })
      .catch((err) => {
        console.log(`${err} while contacting the login API.`);
      });
  }

  ls.config.encrypt = true;
  ls.config.secret = 85;

  // to do login after enter

  useEffect(() => {
    // console.log("all",allValues)
    if (allValues.isChecked ) {
      // console.log("loginEmail",allValues.email)
      // console.log('log',allValues)
      // console.log("login",allValues.email)
      
      // localStorage.setItem('loginEmail',allValues.email)
      // localStorage.setItem('loginPassword',allValues.password)
      // localStorage.setItem('checkbox',allValues.isChecked)

      ls.set('loginEmail', JSON.stringify(allValues.email))
      ls.set('loginPassword', allValues.password)
      ls.set('checkbox', allValues.isChecked)
      
    }
  },[allValues])

 
  let validateLogins = (e) => {

    // console.log("here",allValues)
    
    const form = nameForm.current;
    let emlid = `${form['name'].value}`;
    let pswd = `${form['password'].value}`;
    let UserExist = false;
    let uId
    let userName
    let boolemail = false;
    let boolpswd = false;
    let userRole = 0;

    let data = pswd;
    // Encrypt
    // let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'my-secret-key@123').toString();
    //log encrypted data
    // console.log('Encrypt Data -')
    // console.log(ciphertext);

    // Decrypt
    // let bytes = CryptoJS.AES.decrypt(ciphertext, 'my-secret-key@123');
    // let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    //log decrypted Data
    // console.log('decrypted Data -')
    // console.log(decryptedData);


    // if (allUser.length > 0) {
    //   // console.log(allUser)
    //   for (let i = 0; i < allUser.length; i++) {

    //     if(allUser[i].password !== '' && allUser[i].password !== 'NULL'){
    //       let bytes = CryptoJS.AES.decrypt(allUser[i].password, 'my-secret-key@123');
    //       let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //       // console.log("userpassword",decryptedData)
    //       if ((emlid === allUser[i].emailid || emlid === allUser[i].user_name) && pswd === decryptedData) {
  
    //         UserExist = true;
    //         sessionStorage.setItem('name',allUser[i].user_name)
    //         sessionStorage.setItem('loggedIn','true')
    //         sessionStorage.setItem('email',allUser[i].emailid)
    //         userRole = allUser[i].usertype;
    //         // console.log("alluser", allUser[i], userRole)
    //         userName = allUser[i].user_name
    //         uId = allUser[i].user_id
    //       }
    //     }
     
    //   }



    //   if (UserExist) {
    //     // debugger;
    //     ls.set('uid',uId)
    //     if (userRole == "1") {
    //       navigate('/landingadmin')
    //     } else {
          
    //       navigate('/landinguser')
    //       // navigate(`/landinguser/${uId}`)
    //     }
    //   }
    //   else {
    //     for (let i = 0; i < allUser.length; i++) {
    //       if (emlid === allUser[i].emailid) {
    //         boolemail = true;

    //       }
    //       if (pswd === allUser[i].password) {
    //         boolpswd = true;
    //       }

    //     }
    //     var msg1 = document.getElementById("valMsg1");
    //     var msg2 = document.getElementById("valMsg2");
    //     var msg3 = document.getElementById("valMsg3");

    //     if (!boolemail && !boolpswd) {

    //       msg3.style.display = "block";
    //       msg1.style.display = "none";
    //       msg2.style.display = "none";
    //     }
    //     // msg 'email and password wrong please enter the right credentials'
    //     if (!boolemail) {

    //       msg1.style.display = "block";
    //       msg2.style.display = "none";
    //       msg3.style.display = "none";
    //     }
    //     // msg 'email id is wrong please enter right email'
    //     if (!boolpswd) {

    //       msg2.style.display = "block";
    //       msg1.style.display = "none";
    //       msg3.style.display = "none";
    //     }
    //     // msg 'password is wrong please enter right password'
    //   }
    //   // x.style.display = "block";   //Ddure         
    // }

    

  }
  let onChange = (e) => {
    
    setAllValues({...allValues, [e.target.name]: e.target.value})
    // console.log("all",allValues)

    let newValue = e.target.value;

    oldValueRef.current = e.target.value;

    let UserExist = false;

    if (allUser.length > 0) {
      for (let i = 0; i < allUser.length; i++) {
        if (newValue === allUser[i].emailid || newValue === allUser[i].user_name)
          UserExist = true;

          let bytes = CryptoJS.AES.decrypt(allUser[i].password, 'my-secret-key@123');
          let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          // console.log("username: ",allUser[i].user_name)
          // console.log("userpassword: ",decryptedData)
          // console.log("email: ",allUser[i].emailid)
      }
      var x = document.getElementById("valMsg");
      if (UserExist)
        x.style.display = "none";
      else
        x.style.display = "block";   //Ddure         
    }
  }

  const changeHandler = e => {
    setAllValues({...allValues, [e.target.name]: e.target.value})
 }

 const onChangeCheckbox = (event) => {
  setAllValues({
    email:allValues.email,
    password:allValues.password,
    isChecked: event.target.checked
  });
};

  useEffect(() => {
    // console.log(env.API_URL)

  if(localStorage.checkbox && localStorage.email === null ){
    setAllValues({
      isChecked: false,
      email: '',
      password: ''
    });
  }else
  if (localStorage.checkbox && localStorage.email !== "" ) {
    let dEmail = ls.get('loginEmail', { secret: 85 })
    let dPassword = ls.get('loginPassword', { secret: 85 })
    // console.log('d',dEmail)
      setAllValues({
        isChecked: false,
        email: dEmail ? dEmail.replaceAll('"', ''):"",
        // email:ls.get('loginEmail', { secret: 85 }),
        password: dPassword?dPassword.replaceAll('"', ''):""
        // password:ls.get('loginPassword', { secret: 85 }),
      });
    }
    // UserCall();
  }, []);

  

  return (

    <div>
      <div id="app">
        
        <section class="section loginSection">
          <div class="d-flex flex-wrap align-items-stretch mt-0px">
            <div class="col-lg-7 col-12 order-lg-1 order-1 log-img col-md-6">
              <div class="login-img">
                <img class="img-login" src={imgUrl.login2} />
              </div>
            </div>
            <div class="col-lg-5 col-md-6 col-12 order-lg-2 min-vh-100 order-2 bg-white col-md-6">
              <div class="p-4 m-3 d-flex flex-column align-items-lg-stretch justify-content-center h-100 uscaleDivLog">
                <img src={imgUrl.logo} alt="logo" class="w-25 mb-3 mt-2 uscaleLogo" />
                <h4 class="text-dark font-weight-normal">Welcome to <span class="font-weight-bold">UScale</span></h4>
                <p class="para-content">Before you get started, you must login or register if you don't already have an
                  account.</p>
                <form method="POST" action="#" ref={nameForm} class="needs-validation" novalidate="">
                  <div class="form-group">
                    <label for="name">User/eMail</label>
                    <input id="name" type="email" class="form-control" name="email" tabindex="1" onChange={onChange} required autofocus 
                    value={allValues.email}
                    />


                    <div class="invalid-feedback">
                      Please fill in your email
                    </div>
                  </div>
                  <div class="form-group" style={{ display: "none" }} id="valMsg">
                    <small id="emailHelp" style={{ color: "red" }}>
                      If you don’t already have an account, please ask your account admin to register you. If you don’t know your account admin, please let us know (contact@uscale.digital). UScale is happy to assist.
                    </small>
                  </div>

                  <div class="form-group" style={{ display: "none" }} id="valMsg1">
                    <small id="emailHelp" style={{ color: "red" }}>
                      Email id is wrong please enter right email
                    </small>
                  </div>

                  <div class="form-group">
                    <div class="d-block">
                      <label for="password" class="control-label">Password</label>
                    </div>
                    <input id="password" type="password" class="form-control" name="password" tabindex="2" required onChange={changeHandler}
                    value={allValues.password}
                    />
                    <div class="invalid-feedback">
                      please fill in your password
                    </div>

                    <div class="form-group" style={{ display: "none" }} id="valMsg2">
                      <small id="emailHelp" style={{ color: "red" }}>
                        Password is wrong please enter right password
                      </small>
                    </div>

                    <div class="form-group" style={{ display: "none" }} id="valMsg3">
                      <small id="emailHelp" style={{ color: "red" }}>
                        Email and password wrong please enter the right credentials
                      </small>
                    </div>

                  </div>

                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" name="remember" class="custom-control-input" tabindex="3" id="remember-me" 
                      onChange={onChangeCheckbox}
                      checked={allValues.isChecked}
                      />
                      <label class="custom-control-label" for="remember-me">Remember Me</label>
                    </div>
                  </div>

                  <div class="form-group text-right mobileView">
                    <Link to="/forgot_password">
                      <a href="#" class="float-left mt-3 forpassbtn">
                        Change Password/ Forgot Password?
                      </a>
                    </Link>
                    <a onClick={Login} id='loginButton' class="btn btn-primary  btn-icon icon-right login-button" tabindex="4">
                      Login
                    </a>

                  </div>

                  <div class="mt-5  acc-content" >
                    If you don’t already have an account, please ask your account admin to register you. If you don’t know your account admin, please let us know (contact@uscale.digital). UScale is happy to assist.

                  </div>
                </form>

              </div>
            </div>

          </div>
        </section>
      </div>
    </div>

  )
}

export default Login
