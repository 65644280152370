import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import image1 from "../../assets/img/unsplash/landing.svg";
import logo from "../../assets/img/LOGO-UScale.png";
import bendchStury from "../../assets/img/unsplash/landing/bench-study.JPG";
import emspImg from "../../assets/img/unsplash/landing/emsp.jpg";
import publicStudy from "../../assets/img/unsplash/landing/public-study.JPG";
// import elecVehicle from '../../assets/img/unsplash/elec-vehicle.JPG'
import ele from "../../assets/img/unsplash/landing/elec-vehicle.JPG";
import sales from "../../assets/img/unsplash/landing/after-sales.JPG";
import retailStudy from "../../assets/img/unsplash/landing/retail-study.JPG";
import benchMarkStudy from "../../assets/img/unsplash/landing/bench-study.JPG";
import afterSalesStudy from "../../assets/img/unsplash/landing/after-sales.JPG";

import axios from "axios";
import env from "react-dotenv";
import TopBar from "../TopBar";
import Sidebar from "../Sidebar";
import Loader from "../Loader";

function LandingAdmin({ showSearch,show }) {
    let [id, setID] = useState(0);
    let navigate = useNavigate();
    let [data, setData] = useState([]);
    let [surData, setSurData] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    let params = useParams();

    useEffect(() => {
        if(sessionStorage.getItem('loggedIn') != 'true'){
            navigate('/login')
        }

        if (params.id) {
            // console.log(params.id)
            dashboardData(params.id);
        }
        let uId = localStorage.getItem("userId");
        setID(uId);
        surveyData()
        sideClass()
    }, []);


    const surveyData = async () => {
        setShowLoader(true)
        let sData = await axios.get(`${env.API_URL}/getadminsurvey`)
        // console.log('survey', sData.data);
        setSurData(sData.data)
        if(sData.data){
            setShowLoader(false)
        }
        
    }

    const dashboardData = async (values) => {
        let d = await axios.get(`${env.API_URL}/${values}`);
        // console.log(d.data);
        let obj = {};
        d.data.forEach((element) => {
            if (!obj[element.survey_id]) {
                obj[element.survey_id] = {
                    submodule: [],
                };
            }
            obj[element.survey_id]["submodule"].push(element.survey_txn_id);
        });

        // console.log(obj);
        setData(obj);
    };

    let sideClass = () => {
        
        if (document.body.classList.contains('sidebar-show')) {
            // console.log("doc",document.body.classList)
            document.body.classList.remove('sidebar-show');
            document.body.classList.add('sidebar-mini');
        } 
        else if (document.body.classList.contains('sidebar-mini')) {
            // document.body.classList.remove('sidebar-mini');
            document.body.classList.add('sidebar-mini');
        }
        else if (window.innerWidth < 767){
            document.body.classList.remove('sidebar-mini')
            // console.log("doc",document.body.classList)
            document.body.classList.remove('sidebar-show')
        }

    }

    return (
        <div>
            <div id="app">
                <div className="navbar-bg landing_nav"></div>
                {showLoader ? <div className="loaderWrapper"><Loader data={showLoader}/></div>:""}

                {/* {console.log("this is",showSearch)} */}
                <TopBar iconDisplay={showSearch} />
                <Sidebar active={show}/>
                <div className="main-content" onClick={sideClass}>
                   
                    <section class="studies-section adminSection" id="studiessection">
                        <div class="p-5">
                            <div class="row">
                                {
                                    surData && surData.map((ele) => {
                                        let sid = ele.survey_id
                                        let surveyName = ele.survey_name
                                        return <>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 px-0">
                                                <article class="article adminArticle">
                                                    <div class="article-header">
                                                        <div class={ele.survey_flag == "Y" ?"article-image":"article-image disable"}>                                                        
                                                            <img src={ele.survey_image}
                                                            onClick={() => 
                                                                {
                                                                //  navigate(`/retailadmin/${sid}`);
                                                                navigate(`/retailadmin`);
                                                                 window.localStorage.setItem('adminSurevyName',surveyName)
                                                                 window.localStorage.setItem('adminSurveyId',sid)
                                                                }}
                                                            />
                                                        </div>
                                                        <div class="article-title">
                                                            {/*<!-- <h2><a href="#">Private Charging Infrastructure Retail Study</a></h2> -->*/}
                                                        </div>
                                                    </div>

                                                    <div class="article-details">
                                                        <p class={ele.survey_flag == "Y" ?"article-image":"article-image disable"}>
                                                            {
                                                                ele.survey_description
                                                            }
                                                        </p>
                                                        <div class="article-cta">
                                                            <a href={ele.survey_redirect} target="_blank">Read More</a>
                    
                                                        </div>
                                                        {/* <div class="article-cta">
                                                            <a
                                                                href="https://uscale.digital/en/"
                                                                target="_blank"
                                                                class="btn btn-primary"
                                                            >
                                                                Read More
                                                            </a>
                                                            <button
                                                                // navigate(`/${compName}/${params.id}/${sid}/${subIds}`) 
                                                                class="btn btn-primary ml-2"
                                                            >
                                                                Open dashboard
                                                            </button>
                                                        </div> */}
                                                    </div>
                                                </article>
                                            </div>
                                        </>
                                    })
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default LandingAdmin;
