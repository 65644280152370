const imageUrl = {
    'logo' : require('../img/LOGO-UScale.png').default,
    'avatar1': require('../img/avatar/avatar-1.png').default,
    'brand': require('../RETAIL/BrandInterest.png').default,
    




}
export default imageUrl;