import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Img from "../assets/img/myImg/LOGO-UScale.png";
import avatar1 from "../assets/img/avatar/avatar-1.png";
import imageUrl from "../assets/img/imageUrl";
import env from "react-dotenv";
import moment from "moment";
import TopBar from "./TopBar";
import Sidebar from "./Sidebar";
import { dangerMessage } from '../component/handler/errorhandler'

function Listing_orgList({show}) {
  console.log(`${env.API_URL}`);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  let userName = sessionStorage.getItem("name");
  let navigate = useNavigate();

  useEffect(() => {
    if(sessionStorage.getItem('loggedIn') != 'true'){
      navigate('/login')
  }
    getData();
  }, []);

  let getData = async () => {
    let d = await axios.get(`${env.API_URL}/getorglist`);
    d.data.sort(function (a, b) {
      if (a.org_name < b.org_name) {
        return -1;
      }
      if (a.org_name > b.org_name) {
        return 1;
      }
      return 0;
    })
    setData(d.data);
    sideClass()
  };

  // let handleDelete = async (id) => {
  //   console.log("deleted response1");
  //   try {
  //     let res = await axios.delete(`${env.API_URL}/deleteorg/` + id);
  //     setCount(count + 1);
  //   } catch (err) {
  //     console.log("deleted response3");
  //     console.log(err);
  //   }
  //   getData();
  // };

  let handleDelete = async (id) => {
    console.log("deleted response1");
    dangerMessage("Are you sure you want to delete?", "", async () => {
      try {
        let res = await axios.delete(`${env.API_URL}/deleteorg/` + id);
        setCount(count + 1);
        getData();
      } catch (err) {
        // console.log("deleted response3");
        console.log(err);
      }
      getData();
    }, () => { console.log("false") }, false);
  };

  let sideClass = () => {
        
    if (document.body.classList.contains('sidebar-show')) {
        // console.log("doc",document.body.classList)
        document.body.classList.remove('sidebar-show');
        document.body.classList.add('sidebar-mini');
    } 
    else if (document.body.classList.contains('sidebar-mini')) {
        // document.body.classList.remove('sidebar-mini');
        document.body.classList.add('sidebar-mini');
    }
    else if (window.innerWidth < 767){
        document.body.classList.remove('sidebar-mini')
        // console.log("doc",document.body.classList)
        document.body.classList.remove('sidebar-show')
    }

  }

  return (
    <div>
      <div id="app">
        <div class="main-wrapper">
          <div class="navbar-bg landing_nav"></div>
          <TopBar />
          <Sidebar adminActive={show}/>
          {/*   <!-- Main Content -->*/}

          <div class="main-content"  onClick={sideClass}>
            <section class="section">
              <div class="section-header">
                <h1>Dashboard</h1>
              </div>

              <div class="row">
                <div class="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="text-black-50">Listing Organization </h4>
                      <div
                        class="card-header-action"
                        onClick={() => navigate("/addorgnisation")}
                      >
                        <a href="" class="btn btn-primary">
                          Add Organisation
                        </a>
                      </div>

                      <div
                        class="card-header-action userBtnlist"
                        onClick={() => navigate("/adduser")}
                      >
                        <a
                          href=""
                          style={{ fontSize: "15px" }}
                          class="btn btn-primary"
                        >
                          Add User
                        </a>
                      </div>
                      <div
                        class="card-header-action userBtnlist"
                        onClick={() => navigate("/listing_programs")}
                      >
                        <a
                          href=""
                          style={{ fontSize: "15px" }}
                          class="btn btn-primary"
                        >
                          View All Users
                        </a>
                      </div>
                    </div>
                    <div class="card-body p-0 formContent adminboard">
                      <div class="table-responsive">
                        <table class="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "30%" }}>Organisation</th>
                              <th style={{ width: "30%" }}>Created At</th>
                              <th style={{ width: "20%" }}>Created By</th>
                              <th style={{ width: "20%", textAlign: "center" }}>
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length > 0 &&
                              data.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      style={{ width: "30%" }}
                                      onClick={() =>
                                        navigate(
                                          "/editorganisation/" + item.org_id
                                        )
                                      }
                                    >
                                      {item.org_name}
                                    </td>

                                    <td
                                      style={{
                                        width: "30%",
                                        // cursor: "pointer",
                                      }}
                                    // onClick={() =>
                                    //   navigate("/edituser/" + item.user_id)
                                    // }
                                    >
                                      {moment(item.created_at).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        width: "20%",
                                        // cursor: "pointer",
                                      }}
                                    // onClick={() =>
                                    //   navigate("/edituser/" + item.user_id)
                                    // }
                                    >
                                      {item.created_by}
                                    </td>
                                    <td
                                      style={{
                                        width: "20%",
                                        textAlign: "center",
                                      }}
                                      className="formlastTable"
                                    >
                                      <a class="btn btn-edit  btn-action mr-1" data-toggle="tooltip" title="Edit"
                                        onClick={() =>
                                          navigate(
                                            "/editorganisation/" + item.org_id
                                          )
                                        }
                                      >
                                        <i class="fas fa-pencil-alt"></i></a>
                                      <a
                                        class="btn btn-view  btn-action mr-1"
                                        data-toggle="tooltip"
                                        title="View"
                                        onClick={() =>
                                          navigate(
                                            "/viewdetailorg/" + item.org_id
                                          )
                                        }
                                      >
                                        <i class="fas fa-file-alt"></i>
                                      </a>
                                      <a
                                        class="btn btn-delete btn-action"
                                        onClick={() =>
                                          handleDelete(item.org_id)
                                        }
                                        data-toggle="tooltip"
                                        title="Delete"
                                        data-confirm="Are You Sure?|This action can not be undone. Do you want to continue?"
                                        data-confirm-yes="alert('Deleted')"
                                      >
                                        <i class="fas fa-trash"></i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <footer class="main-footer">
            {/* 
            <!-- <div class="footer-left">
              Copyright &copy; 2018 <div class="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval
                Azhar</a>
            </div>
            <div class="footer-right">
              2.3.0
            </div> -->
        */}
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Listing_orgList;
