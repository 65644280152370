import React, { useEffect } from 'react'
import {Link, useNavigate} from 'react-router-dom'
import image1 from '../assets/img/unsplash/landing.svg'
import logo from '../assets/img/LOGO-UScale.png'
import bendchStury from '../assets/img/unsplash/landing/bench-study.JPG'
import emspImg from "../assets/img/unsplash/landing/emsp.jpg"
import publicStudy from '../assets/img/unsplash/landing/public-study.JPG'
import retailStudy from '../assets/img/unsplash/landing/retail-study.JPG'
// import elecVehicle from '../assets/img/unsplash/elec-vehicle.JPG'
import ele from '../assets/img/unsplash/landing/elec-vehicle.JPG'
import sales from '../assets/img/unsplash/landing/after-sales.JPG'


function Landing() {

  let navigate = useNavigate()
    return (
        <div>
        <div id="app">
        <section class="section landing-section landing-section-org landing-section-home">
          <div class="row h-100 mt-0px">
            <div class="col-lg-6 m-auto col-xs-12">
              <img src={logo} alt="logo" class="w-50 mb-5 mt-2 uscaleLogo" />
              <h1 class="mb-4 main-heading">Welcome to UScale Customer Insights Platform!</h1>
              <h5 class="mb-4 para-content">We offer insights on all customer touch points of the eMobility Customer Journey</h5>
              <div className='uscaleLandBtn' style={{marginTop:"15px"}}>
              <Link to='/login'>
                <button class="btn btn-primary btn-icon icon-right mb-5">
                  <a href="" class="study-link text-white">Login</a>
                  
                  <i class="fas fa-arrow-right"></i>
                </button>
                </Link>
                
                <button class="btn btn-primary btn-icon icon-right mb-5 ml-3"
                  
                  >
                  <a href="" class="study-link text-white"
                  onClick={()=> navigate('/samplelanding')}
                  >Access to sample dashboard</a>
                  <i class="fas fa-arrow-right"></i>
                </button>
              </div>
    
            </div>
            <div className="col-6 d-flex firstSectionImgWrapper">
              <img src={image1} class="w-100" className="firstSectionImg"/>
            </div>
          </div>
        </section>
        </div>  
      </div>
    )
}

export default Landing
