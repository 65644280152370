const imgUrl = {

    'logo': require('../img/LOGO-UScale.png'),
    'login2': require('../img/unsplash/login-2.png'),
    'afterScale1': require('../img/afterscales/Bild1.png'),
    'afterScale2': require('../img/afterscales/Bild2.png'),
    'afterScale3': require('../img/afterscales/Bild3.png'),
    'afterScale4': require('../img/afterscales/Bild4.png'),
    'afterScale5': require('../img/afterscales/Bild5.png'),
    'afterScale6': require('../img/afterscales/Bild6.png'),
    'afterScale7': require('../img/afterscales/Bild7.png')
}

export default imgUrl