import React, {useState,useEffect} from "react";
import frgtPswdimg from '../assets/img/unsplash/Forgot-password-2.svg';
import logoimg from '../assets/img/LOGO-UScale.png';
import env from 'react-dotenv';
import axios from "axios";

function Forgot_password() {

  const [allUser, getAllUsers] = useState([]);
  const CryptoJS = require("crypto-js");
  const nameForm=React.useRef(null)

  const UserCall = () => {    
    fetch(
        `${env.API_URL}/getUser` 
      )
    .then((response) => response.json())
    .then((data) => {
        getAllUsers(data);
    })
    .catch((err) => {
        console.log(`${err} while contacting the quote API.`);
    });
  }

 let handleKeyUp = (e) => {
    
    const form=nameForm.current;
    let emlid=`${form['email'].value}`;
    // var mailformat = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/;
    var mailformat="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$";
    if(emlid.match(mailformat))
    {
    // alert("You have entered a valid email address!");    //The pop up alert for a valid email address
    //document.getElementById("email").focus();
    return true;
    }
    else
    {
    alert("You have entered an invalid email address!");    //The pop up alert for an invalid email address
    //document.getElementById("email").focus();
    return false;
    }
}
  

  let handleEmailSend = async(e) =>{
    e.preventDefault();
    // debugger;
    let newValue = e.target.value;
    const form=nameForm.current;
    let emlid=`${form['email'].value}`;    
   // oldValueRef.current = e.target.value;
    let userName="";
    let pswrd="";
    let UserExist=false;

    if(allUser.length > 0 )
    {      
      for(let i=0;i<allUser.length; i++)
      {
        if(emlid == allUser[i].emailid)  
        {
          UserExist=true;
          userName =allUser[i].user_name;

          let bytes = CryptoJS.AES.decrypt(allUser[i].password, 'my-secret-key@123');
          let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

          // pswrd=allUser[i].password;
          pswrd = decryptedData;
        }      
          
      }
      var x = document.getElementById("valMsg");
      if(UserExist)
      {
        x.style.display = "none";
        try {
            let res = await axios
            .post(`${env.API_URL}/sendEmail`, {
              emailid: emlid,
              userName:userName,
              pswd:pswrd
            })
            .then((res) => {
              // alert(" email address!"); 
              document.getElementById("emailNotification").style.display="block"
              // console.log('Email res: ',res)
              if (res.data.status === "FAIL") {
                console.log('Email status: ',res.data.message);
              } else {
                // console.log('Email status2: ',res);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }        
      else 
        x.style.display = "block";   //Ddure         
    }
  };

  useEffect(() => {  
    // console.log(env.API_URL)
    UserCall();
  }, []); 
  
    return (
        <div>
        <div id="app">
        <section class="section">
          <div class="d-flex flex-wrap align-items-stretch mt-0px">
            <div class="col-lg-7 col-12 order-lg-1 order-1 log-img col-md-6">
              <div class="login-img"><img src={frgtPswdimg} class="w-75" /></div>
            </div>
            <div class="col-lg-5 col-md-6 col-12 order-lg-2 min-vh-100 order-2 bg-white col-md-6">
              <div class="p-4 m-3 d-flex flex-column justify-content-center h-100">
                <img src={logoimg} alt="logo" class="w-25 mb-5 mt-2"/>
                <h4 class="text-dark font-weight-normal">Welcome to <span class="font-weight-bold">UScale</span></h4>
    
                <div class="card shadow-none">
                  <div class="card-header pl-0">
                    <h4 class="text-black-50">Forgot Password</h4>
                  </div>
    
                  <div class="card-body pl-0">
                    <p class="para-content">We will send a link to reset your password</p>
                    <form ref={nameForm}>
                      <div class="form-group">
                        <label for="email">Email</label>
                        <input id="email" type="email" class="form-control" name="email" onBlur={handleKeyUp} tabindex="1" required autofocus/>
                      </div>
                      <div class="form-group" style={{display:"none"}} id="valMsg">
                      <small id="emailHelp" style={{color: "red"}}>
                     Email is not exist in database, please request to your admin to create new user.
                      </small>      
                    </div>
                    <div class="form-group" style={{display:"none"}} id="emailNotification">
                      <small id="emailHelp" style={{color: "red"}}>
                      Reset password link has been sent to your email address. Please check.
                      </small>      
                    </div>
                    
                      <div class="form-group">
                        <button class="btn btn-primary  btn-block" onClick={handleEmailSend} tabindex="4">
                          Forgot Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
    
          </div>
        </section>
      </div>
        </div>
    )
}

export default Forgot_password
