import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { default as ReactSelect } from "react-select";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Img from "../assets/img/myImg/LOGO-UScale.png";
import avatar1 from "../assets/img/avatar/avatar-1.png";
import TopBar from "./TopBar";
import Sidebar from "./Sidebar";
import env from "react-dotenv";
import { components } from "react-select";

function EditUser({show}) {
  let navigate = useNavigate();
  let params = useParams();
  let [orgName, setOrgName] = useState("");
  let [emailid, setEmailid] = useState("");
  let [userName, setUserName] = useState("");
  let [label, setLabel] = useState("");
  let [value, setValue] = useState("");
  let [data, setData] = useState([]);
  const [modSurveyData, setModSurveyData] = useState([]);
  const [userSurveys, setUserSurveys] = useState([]);
  const [userSubSurveys, setUserSubSurveys] = useState([]);
  const [newSurveyList, setNewSurveyList] = useState([]);
  const [optionSelected, setOptionSelected] = useState([]);
  const [dataSubSurvey, setSubSurData] = useState([]);
  const [selectedSubSurveyID, setSelectedSubSurveyID] = useState([]);

  const [selectarr, setSelectArr] = useState({
    label: "Select all",
    value: "*",
  });
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const getSubSurvey = (selectedSubs) => {
    // console.log(selectedSubs);
    setSelectedSubSurveyID(selectedSubs);
  };
  let getSubSurveyData = async (id, survey_name, flag) => {
    try {
      //console.log("ID: ", id);
      let d = await axios.get(`${env.API_URL}/getsubsurveylist/${id}`);
      // console.log("SubSurvey: ", d.data);
      let arr = [];
      d.data.forEach((ele) => {
        let obj = {
          value: id + "_" + ele.survey_txn_id,
          label: flag === true ? survey_name + "_" + ele.tabname : ele.tabname,
        };
        arr.push(obj);
      });
      return arr;
      //setSubSurData([...arr]);
    } catch (err) {
      return [];
      //console.log("SubSurveyErr: ", err);
    }
  };
  const getSelectedSurvey = async (sSur, type) => {
    // console.log(sSur);
    setOptionSelected(sSur);
    setSelectedSubSurveyID([]);
    let mainArr = [];
    let indResp = 0;
    setSubSurData([]);
    if (sSur.length > 0) {
      let name = sSur.length === 1 ? false : true;
      sSur.forEach(async (sid) => {
        let dropdownData = await getSubSurveyData(
          sid["value"],
          sid["label"],
          name
        );
        if (dropdownData.length > 0) {
          indResp++;
          setSelectArr({ label: "Select all", value: "*" });
          mainArr = [...mainArr, ...dropdownData];
          if (indResp === sSur.length) {
            // console.log("mainArr", mainArr);
            setSubSurData(mainArr);
            setSelectedSubSurveyID(mainArr);
          }
        }
      });
      // if (mainArr.length > 0) {
      //   console.log(mainArr);
      //   setSubSurData(mainArr);
      // }
    } else {
      setSubSurData([]);
    }
  };
  let getSurveyData = async () => {
    let d = await axios.get(
      `${env.API_URL}/getsurveylist`
    );
    // console.log("SurveyList for User:", d.data);
    let newArr = [];

    d.data.forEach((element) => {
      let obj = {
        label: element.survey_name,
        value: element.survey_id,
        // modules:element.tabname
      };
      newArr.push(obj);
    });
    // console.log("newArr", newArr);
    setModSurveyData([...newArr]);
  };

  let getData = async () => {
    let d = await axios.get(`${env.API_URL}/getOrgList`);
    setData(d.data);
    // console.log(d.data);
  };

  let getUserData = async () => {
    let dta = await axios.get(
      `${env.API_URL}/editsingleuser/${params.id}`
    );
    // setData(dta.data);
    // console.log(dta.data);
    setUserName(dta.data[0].user_name);
    setEmailid(dta.data[0].emailid);
    setValue(dta.data[0].org_id);

    let userSurveyArr = dta.data.map((oj) => oj["survey_id"]);
    let userSubSurArr = dta.data.map((oj) => oj["survey_txn_id"]);
    setUserSurveys(userSurveyArr);
    setUserSubSurveys(userSubSurArr);
  };

  useEffect(() => {
    if(sessionStorage.getItem('loggedIn') != 'true'){
      navigate('/login')
  } 
  sideClass()
    // console.log(params);
    if (params.id) {
      // console.log(params);
      getSurveyData();
      getData();
      
      // getSingleData();
    }
  }, []);

  let sideClass = () => {
        
    if (document.body.classList.contains('sidebar-show')) {
        // console.log("doc",document.body.classList)
        document.body.classList.remove('sidebar-show');
        document.body.classList.add('sidebar-mini');
    } 
    else if (document.body.classList.contains('sidebar-mini')) {
        // document.body.classList.remove('sidebar-mini');
        document.body.classList.add('sidebar-mini');
    }
    else if (window.innerWidth < 767){
        document.body.classList.remove('sidebar-mini')
        // console.log("doc",document.body.classList)
        document.body.classList.remove('sidebar-show')
    }

}

  useEffect(() => {
    if (modSurveyData && modSurveyData.length > 0) {
      getUserData();
    }
  }, [modSurveyData]);
  useEffect(() => {
    if (modSurveyData && modSurveyData.length > 0 && value != "") {
      // let url = "http://localhost:3011/singleorgdata/" + params.id;
      async function getOrgData() {
        await fetch(`${env.API_URL}/getorg/${value}`)
          .then((response) => response.json())
          .then((data) => {
            // console.log("here we get single", data);
            if (data.length > 0) {
              // setUserName(data[0].user_name);
              // setEmailid(data[0].emailid)
              setLabel(data[0].org_name);
              let arr = data.map((oj) => oj["survey_id"]);
              // console.log("this is data", arr);
              let newArr = [];
              let dummy = [];

              let filterArr = arr.filter((item, index) => {
                return arr.indexOf(item) == index;
              });
              // console.log("filter", filterArr);

              modSurveyData.forEach((ele, i) => {
                // console.log(ele);
                if (filterArr.includes(ele.value)) {
                  newArr.push(ele);
                  // if(newArr.length == 0){
                  //   newArr.push(ele)
                  // }else if(newArr.length !== 0){
                  //   newArr.forEach((item,i) => {
                  //     dummy.push(item.value)
                  //   })
                  //   if(!dummy.includes(ele.value)){
                  //     newArr.push(ele)
                  //   }
                  // }
                }
              });
              setNewSurveyList([...newArr]);

              // let mainArr = [];
              // let indResp = 0;
              // if (newArr.length > 0) {
              //   let name = newArr.length === 1 ? false : true;
              //   console.log("newArr", newArr);
              //   newArr.forEach(async (sid) => {
              //     let dropdownData = await getSubSurveyData(
              //       sid["value"],
              //       sid["label"],
              //       name
              //     );
              //     console.log("dropdownData", dropdownData);
              //     if (dropdownData.length > 0) {
              //       indResp++;
              //       setSelectArr({ label: "Select all", value: "*" });
              //       mainArr = [...mainArr, ...dropdownData];
              //       console.log(indResp === newArr.length, indResp, newArr);
              //       if (indResp === newArr.length) {
              //         console.log("mainArr", mainArr);
              //         setSubSurData(mainArr);
              //         setSelectedSubSurveyID(mainArr);
              //       }
              //     }
              //   });
              //   // if (mainArr.length > 0) {
              //   //   console.log(mainArr);
              //   //   setSubSurData(mainArr);
              //   // }
              // } else {
              //   setSubSurData([]);
              // }
            }
            //setUserName(data[0].user_name);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      getOrgData();
    }
  }, [modSurveyData, value]);
  useEffect(() => {
    if (
      userSurveys &&
      userSurveys.length > 0 &&
      userSubSurveys &&
      userSubSurveys.length > 0 &&
      newSurveyList &&
      newSurveyList.length > 0
    ) {
      let mainArr = [];
      let newArr = [];
      let indResp = 0;
      // let filterArr = arr.filter((item, index) => {
      //   return arr.indexOf(item) == index;
      // });
      // console.log("filter", filterArr);
      newSurveyList.forEach((ele, i) => {
        // console.log(ele);
        if (userSurveys.includes(ele.value)) {
          newArr.push(ele);
        }
      });
      setOptionSelected([...newArr]);
      if (newArr.length > 0) {
        let name = newArr.length === 1 ? false : true;
        // console.log("newArr", newArr);
        newArr.forEach(async (sid) => {
          let dropdownData = await getSubSurveyData(
            sid["value"],
            sid["label"],
            name
          );
          // console.log("dropdownData", dropdownData);
          if (dropdownData.length > 0) {
            indResp++;
            setSelectArr({ label: "Select all", value: "*" });
            mainArr = [...mainArr, ...dropdownData];
            // console.log(indResp === newArr.length, indResp, newArr);
            if (indResp === newArr.length) {
              // console.log("mainArr", mainArr);
              setSubSurData(mainArr);
              let newSubArr = [];
              mainArr.forEach((ele, i) => {
                if (userSubSurveys.includes(ele.value.split("_")[1] * 1)) {
                  newSubArr.push(ele);
                }
              });
              setSelectedSubSurveyID([...newSubArr]);
            }
          }
        });
        // if (mainArr.length > 0) {
        //   console.log(mainArr);
        //   setSubSurData(mainArr);
        // }
      } else {
        setSubSurData([]);
      }
    }
  }, [userSurveys, userSubSurveys, newSurveyList]);

  // let getSurveyData = async () => {
  //     let res = await axios.get(`${env.API_URL}/getallsurveydata`);
  //     console.log("SurveyList:", res.data);
  //     setSurData(res.data);
  // };

  const getSelectedSurveys = (selected) => {
    // console.log(selected);
    setOptionSelected([...selected]);
  };

  let handleSelect = (e) => {
    const label = e.substring(e.indexOf(",") + 1);
    const title = label.split(",")[0];
    const val = e.split(",")[0];
    setValue(val);
    setLabel(title);
  };

  let handleSave = async () => {
    if (
      optionSelected &&
      optionSelected.length > 0 &&
      selectedSubSurveyID &&
      selectedSubSurveyID.length > 0
    ) {
      let surveyIds = optionSelected.map((oj) => oj["value"]);
      let subModIds = selectedSubSurveyID.map((oj) => oj["value"]);
      await axios
        .post(`${env.API_URL}/edituser`, {
          user_id: params.id,
          user_name: userName,
          survey_txnId: subModIds,
          survey_id: surveyIds,
          modified_by: sessionStorage.getItem('name'),
          emailid: emailid,
        })
        .then((res) => {
          // console.log(res);
          navigate("/listing_programs");
        });
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <div>
      <div id="app">
        <div className="main-wrapper">
          <div className="navbar-bg"></div>

          <TopBar />
          <Sidebar adminActive={show}/>

          {/*   <!-- Main Content -->*/}

          <div className="main-content" onClick={sideClass}>
            <section className="section">
              <div className="section-header">
                <h1>Dashboard</h1>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="text-black-50">Edit User</h4>
                      <div
                        className="card-header-action"
                        onClick={() => navigate("/listing_programs")}
                      >
                        <a href="" className="btn btn-primary">
                          View All Users
                        </a>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <Form className="formContent">
                        <div className="divFlex">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                              value={userName}
                              onChange={(e) => setUserName(e.target.value)}
                              type="text"
                              placeholder="User name"
                              name="orgName"
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>User Email</Form.Label>
                            <Form.Control
                              onChange={(e) => setEmailid(e.target.value)}
                              //   onBlur={handleKeyUp}
                              type="text"
                              placeholder="User email"
                              name="emailid"
                              value={emailid}
                              disabled={true}
                            />
                            
                          </Form.Group>
                        </div>
                        <div className="dropDownblock">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>Organisation</Form.Label>
                            <DropdownButton
                              class="dropdown-item dropdownMenu"
                              style={{ marginBottom: "15px", width: "40%" }}
                              className="alignRight"
                              title={label !== "" ? label : "--Select--"}
                              id="dropdown-menu-align-right"
                              onSelect={handleSelect}
                              disabled={true}
                            >
                              {data &&
                                data.map((item, i) => {
                                  return (
                                    <div key={i}>
                                      <Dropdown.Item
                                        eventKey={[
                                          item.org_id,
                                          item.org_name,
                                          item.Survey_Id,
                                        ]}
                                      >
                                        {item.org_name}
                                      </Dropdown.Item>
                                    </div>
                                  );
                                })}
                            </DropdownButton>
                          </Form.Group>
                        </div>
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Edit Surveys</Form.Label>
                          <ReactSelect
                            isMulti
                            allowSelectAll={true}
                            options={[selectarr, ...newSurveyList]}
                            // onChange={getSelectedSurveys}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            value={optionSelected}
                            onChange={(selected) => {
                              if (
                                selected !== null &&
                                selected.length > 0 &&
                                selected[selected.length - 1].value ===
                                  selectarr.value
                              ) {
                                return getSelectedSurvey(newSurveyList, "all");
                              }
                              return getSelectedSurvey(selected, "single");
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Sub Module</Form.Label>
                          <ReactSelect
                            isMulti
                            components={{ Option, MultiValue }}
                            allowSelectAll={true}
                            options={[selectarr, ...dataSubSurvey]}
                            // onChange={getSubSurvey}
                            onChange={(selected) => {
                              if (
                                selected !== null &&
                                selected.length > 0 &&
                                selected[selected.length - 1].value ===
                                  selectarr.value
                              ) {
                                return getSubSurvey(dataSubSurvey);
                              }
                              return getSubSurvey(selected);
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            value={selectedSubSurveyID}
                          />
                          {/* <DropdownButton
                              style={{ marginBottom: "15px" }}
                              className="alignRight menuItem"
                              title={
                                thirdlabel != "" ? thirdlabel : "--Select--"
                              }
                              id="dropdown-menu-align-right"
                              onSelect={handleSelectSubSurvey}
                            >
                              {dataSubSurvey &&
                                dataSubSurvey.map((item, i) => {
                                  return (
                                    <div key={i}>
                                      <Dropdown.Item
                                        // className='menuItem'
                                        eventKey={[
                                          item.survey_txn_id,
                                          item.tabname,
                                        ]}
                                      >
                                        {item.tabname}
                                      </Dropdown.Item>
                                    </div>
                                  );
                                })}
                            </DropdownButton> */}
                        </Form.Group>

                        <Button variant="primary" onClick={handleSave}>
                          Submit
                        </Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <footer className="main-footer">
            {/* 
            <!-- <div className="footer-left">
              Copyright &copy; 2018 <div className="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval
                Azhar</a>
            </div>
            <div className="footer-right">
              2.3.0
            </div> -->
        */}
          </footer>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
