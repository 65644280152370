import React, { useEffect, useState } from "react";
import "../../assets/css/surveyCss.css";
import logo from "../../assets/img/LOGO-UScale.png";
import { useParams, useNavigate } from "react-router-dom";
import brand from "../../assets/RETAIL/BrandInterest.png";
import website from "../../assets/RETAIL/WebsiteVisits.png";
import axios from "axios";
import dealer from "../../assets/RETAIL/DealerVisits.png";
import drives from "../../assets/RETAIL/testdrives.png";
import finance from "../../assets/RETAIL/finance.png";
import delivery from "../../assets/RETAIL/delivery.png";
import followup from "../../assets/RETAIL/followup.png";
import driving from "../../assets/Benchmark/Driving7963300015927257.png";
import diplay from "../../assets/Benchmark/Display01677025792450637.png";
import naving from "../../assets/Benchmark/Naving2868597297686222.png";
import AIr from "../../assets/Benchmark/AIr578545480976038.png";
import charging from "../../assets/Benchmark/Charging5849141785961023.png";
import connect from "../../assets/Benchmark/Connect05760734071395901.png";
import Bild from "../../assets/Benchmark/Bild27371396642081265.png";


import env from "react-dotenv";
import TopBar from "../TopBar";
import Sidebar from "../Sidebar";
import Popup from "../Popup";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from "../Loader";


const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
}));
function RetailAdmin({ showSearch, active }) {

    const classes = useStyles();

    let params = useParams();
    let navigate = useNavigate()
    const [modalShow, setModalShow] = React.useState(false);

    let [subIdArr, setIdArr] = useState([]);
    let [iframelink, setIframLink] = useState("");
    let [show, setShow] = useState(false);
    let [surLink, setSurLink] = useState([]);
    let [showClass, setShowClass] = useState(false)
    let [showSubAdminSurvey, setshowSubAdminSurvey] = useState(false)
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if(sessionStorage.getItem('loggedIn') != 'true'){
            navigate('/login')
        }
        if (params) {
            // console.log(params)
        }
        // if (params.id) {
        //     getSurveyLink(params.id)
        //     console.log(surLink)
        //     sideClass()
        // }
        if(window.localStorage.getItem('adminSurveyId') !=""){
           let surveyId =  window.localStorage.getItem('adminSurveyId').toString()
           getSurveyLink(surveyId)
           sideClass()
        }
    }, []);

    let arrImg = params.id == 3 ? [brand, website, dealer, drives, finance, delivery, followup] :
        params.id == 2 ? [driving, diplay, naving, AIr, charging, connect, Bild] : params.id == 6 ? [brand] : ""

    let getSurveyLink = async (id) => {
        // let sLink = await axios.get(`${env.API_URL}/getadminsurveylink/${id}`)
        setShowLoader(true)
        let sLink = await axios.get(`${env.API_URL}/getadminsurveylink/${id}`)
        // console.log("Link data", sLink.data)
        if(sLink.data){
            setShowLoader(false)
        }
        setSurLink(sLink.data)
    }

    let sideClass = () => {
        
        if (document.body.classList.contains('sidebar-show')) {
            // console.log("doc",document.body.classList)
            document.body.classList.remove('sidebar-show');
            document.body.classList.add('sidebar-mini');
        } 
        else if (document.body.classList.contains('sidebar-mini')) {
            // document.body.classList.remove('sidebar-mini');
            document.body.classList.add('sidebar-mini');
        }
        else if (window.innerWidth < 767){
            document.body.classList.remove('sidebar-mini')
            // console.log("doc",document.body.classList)
            document.body.classList.remove('sidebar-show')
        }

    }

    let handleIframe = (link) => {
        setIframLink('');
        setIframLink(link)
        // console.log(iframelink)
        setShow(true)
        setShowClass(true)
    }
    const childToParentAdmin = (val) => {
        setShow(val);
        setIframLink('')
        setshowSubAdminSurvey(false)
      }
    return (
        <div>
            <div id="app">

                <div className="navbar-bg landing_nav"></div>
                {showLoader ? <div className="loaderWrapper"><Loader data={showLoader}/></div>:""}

                {/* {console.log("this is",showSearch)} */}
                <TopBar 
                showadminSurvey={true} 
                showSubAdminSurvey={showSubAdminSurvey} 
                childToParentAdmin={childToParentAdmin}/>
                <Sidebar active={active}/>
                <div className="main-content" onClick={sideClass}>

                    {/* removing class mx-4 */}
                    <section className={showClass ? "retail-section subSectionTwoAdmin" : "retail-section"}>
                        {/* <section className={showClass ? "retail-section" : "retail-section"}> */}


                        <div className="categorydiv adminPortaldiv"
                            style={{ display: show ? "none" : "block" }}
                        >
                            <div className="adminCards">
                            <h4 className="ret-title" style={{textAlign:"center"}}>
                                {/* here I am taking survey name */}
                                {window.localStorage.getItem('adminSurevyName').toString()}
                            </h4>
                                <div>
                                    <h6 className="retail-title mb-4">Please select a category...</h6>
                                </div>

                                <div className="justify-content-between adminPortal">
                                    {/* <div className="card-container"> */}
                                    {
                                        surLink && surLink.map((ele, index) => {
                                            return <>
                                                {
                                                    <div>
                                                        <div
                                                            className={params.id == 3 ? "card retail-card main-card" : params.id == 2 || 6 ? "card bench-card main-card" : ""}
                                                            onClick={() => { handleIframe(ele.reportlink); setModalShow(false);setshowSubAdminSurvey(true) }}
                                                        >
                                                            <div className="p-4 cardImage">
                                                                <img
                                                                    style={{width:"100%"}}
                                                                    src={ele.survey_submdule_image}
                                                                    className={params.id == 3 ? "card-img-top car-img " : params.id == 2 ? "card-img-top bench-img" : ""}
                                                                    alt="..."
                                                                />
                                                            </div>
                                                            <div className="card-body text-center pt-0 pb-0">
                                                                <h6 className="card-title mb-0 d-flex justify-content-center align-items-center h-100">
                                                                    {ele.tabname.charAt(0).toUpperCase() + ele.tabname.slice(1)}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="iframContainer" style={{ display: show ? "block" : "none", marginTop: "40px" }}>
                            <div className="iframe-wrapper" id="iFrameDiv">
                                {/* <i class="fa fa-arrow-left closeButton" aria-hidden="false"
                                style={{fontSize:"30px"}}
                                onClick={() => {setShow(false) ;setIframLink('');setShowClass(false)}}    
                                ></i> */}
                                {/* <button className="btn btn-dark closeButton" onClick={() => {setShow(false) ;setIframLink('');setShowClass(false)}}>Show Submodules</button> */}
                                {iframelink != "" ? (
                                    <iframe
                                        src={iframelink}
                                        height="200"
                                        width="300"
                                        title="Iframe Example"
                                        frameBorder="0"
                                    ></iframe>

                                ) : (
                                    ""
                                )}

                            </div>
                        </div>
                        {/* <Popup      

                                    show={modalShow}
                                    frame={iframelink}
                                    onHide={() => setModalShow(false)}
                                /> */}
                    </section>
                </div>
            </div>
        </div>
    );
}

export default RetailAdmin