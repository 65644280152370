import React, { useState, useEffect } from 'react'
import "../assets/css/style.css";
import frgtPswdimg from '../assets/img/unsplash/Forgot-password-2.svg'
import logoimg from '../assets/img/LOGO-UScale.png'
import axios from "axios";
import env from 'react-dotenv';
import {Link, useNavigate} from "react-router-dom";

function ResetPassword() {
  const nameForm=React.useRef(null);
  const CryptoJS = require("crypto-js");
  const [allUser, getAllUsers] = useState([]);
  const navigate = useNavigate();

  const UserCall = () => {    
    fetch(
        `${env.API_URL}/getUser` 
      )
    .then((response) => response.json())
    .then((data) => {
        getAllUsers(data);
    })
    .catch((err) => {
        console.log(`${err} while contacting the quote API.`);
    });
}
  //query string read from reset password URL
  let params = (new URL(document.location)).searchParams;
  
  let value =  params.get("email"); //params.get("email"); 
  // console.log('EMail: ',value);

  let onChange = (e) => { 
    
    let myInput = document.getElementById("psw");
    let letter = document.getElementById("letter");
    let capital = document.getElementById("capital");
    let number = document.getElementById("number");
    let length = document.getElementById("length");
    let SpecialChar = document.getElementById("SpecialChar");
    
     document.getElementById("message").style.display = "block";
    // document.getElementById("message").style.display = "none";
    // Validate lowercase letters
    var lowerCaseLetters = /[a-z]/g;
    if(myInput.value.match(lowerCaseLetters)) {  
      letter.classList.remove("invalid");
      letter.classList.add("valid");
    } else {
      letter.classList.remove("valid");
      letter.classList.add("invalid");
    }
    
    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if(myInput.value.match(upperCaseLetters)) {  
      capital.classList.remove("invalid");
      capital.classList.add("valid");
    } else {
      capital.classList.remove("valid");
      capital.classList.add("invalid");
    }
  
    // Validate numbers
    var numbers = /[0-9]/g;
    if(myInput.value.match(numbers)) {  
      number.classList.remove("invalid");
      number.classList.add("valid");
    } else {
      number.classList.remove("valid");
      number.classList.add("invalid");
    }
    
    // Validate numbers
    var SpecialChars = /[!#@$%&?]/g;
    if(myInput.value.match(SpecialChars)) {  
      SpecialChar.classList.remove("invalid");
      SpecialChar.classList.add("valid");
    } else {
      SpecialChar.classList.remove("valid");
      SpecialChar.classList.add("invalid");
    }   
    
    // Validate length
    if(myInput.value.length >= 8) {
      length.classList.remove("invalid");
      length.classList.add("valid");
    } else {
      length.classList.remove("valid");
      length.classList.add("invalid");
    }

    if(letter.classList.contains('valid') && capital.classList.contains('valid') && number.classList.contains('valid') && SpecialChar.classList.contains('valid') && length.classList.contains('valid') )
    document.getElementById("message").style.display = "none";
  }

let checkOldPassword = async(e) => {
  const form=nameForm.current;  
  let oldpswrd=`${form['OldPassword'].value}`; 
  let resultPassword ="";
  let UserExist=false;
  if( oldpswrd !== "" )
  {
    
    //need to test
    // let d = await axios.get(`http://localhost:3004/getUserbyPassword?emailid=${value}&password=${oldpswrd}`);
    // console.log("Email Data:", d.data);

    if(allUser.length > 0 )
    {      
      for(let i=0;i<allUser.length; i++)
      {
        let bytes = CryptoJS.AES.decrypt(allUser[i].password, 'my-secret-key@123');
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        if(value === allUser[i].emailid && oldpswrd === decryptedData)        
          UserExist=true;          
      }

      var x = document.getElementById("valMsg3");
      if(UserExist)     
        x.style.display = "none";       
      else      
        x.style.display = "block";   //Ddure         
    }
    // try {
    //   let res = await fetch(`http://localhost:3000/getUserbyPassword?emailid=${value}&password=${oldpswrd}`, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json'  
    //     }
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //        // navigate('/listing_programs')
    //        if(data==="")
    //        {
    //         resultPassword="";
    //         document.getElementById("valMsg3").style.display="block";
    //        }
    //        else
    //        {
    //         resultPassword=data;
    //        }
    //     })
    // } catch (error) {
    //   console.log(error);
    // }  
  }
  else{
    document.getElementById("valMsg3").style.display="block";
  }
}

// When the user starts to type something inside the password field
let handleConfirm = (e) => {
  const form=nameForm.current;
  let newpswrd=`${form['NewPassword'].value}`;
  let confpswrd=`${form['ConfirmPassword'].value}`; 
  
  
  if(newpswrd === confpswrd)
  {
    document.getElementById("valMsg5").style.display="none";
  }
  else{
    document.getElementById("valMsg5").style.display="block";
  }
 
}

let handlePasswordSave = async(e) => {
  e.preventDefault();
  const form=nameForm.current;
  let newpswrd=`${form['NewPassword'].value}`;
  let confpswrd=`${form['ConfirmPassword'].value}`; 

  if(newpswrd === confpswrd)
  {
    document.getElementById("valMsg2").style.display ="none";
    let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(newpswrd), 'my-secret-key@123').toString();
    // console.log('Encrypt Data -')
    // console.log(ciphertext);  
      try {
         await fetch(env.API_URL+'/edituserpassword/', {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({          
              "password": ciphertext,
              "emailid": value
          })
         })
         .then(response => response.json())
         .then(data => {
          document.getElementById("resetPassNotification").style.display ="block";
          //  console.log('updated record: ',data);
            //  navigate('/login');
         })
         .catch((err) => {
          console.log('Update password error: ',err)
      })
        } catch (error) {
          console.log('Edit user password: ',error);
        } 
        // alert("Password has beed changed");
  }
  else
  document.getElementById("valMsg2").style.display ="block";
}
useEffect(() => {  
  UserCall();
}, []);

    return (
        <div>
        <div id="app">
        <section class="section">
          <div class="d-flex flex-wrap align-items-stretch mt-0px">
            <div class="col-lg-7 col-12 order-lg-1 order-1 log-img col-md-6">
              <div class="login-img"><img src={frgtPswdimg} class="w-75" /></div>
            </div>
            <div class="col-lg-5 col-md-6 col-12 order-lg-2 min-vh-100 order-2 bg-white col-md-6">
              <div class="p-4 m-3 d-flex flex-column justify-content-center h-100">
                <img src={logoimg} alt="logo" class="w-25 mb-5 mt-2"/>
                <h4 class="text-dark font-weight-normal">Welcome to <span class="font-weight-bold">UScale</span></h4>
    
                <div class="card shadow-none">
                  <div class="card-header pl-0">
                    <h4 class="text-black-50">Reset Password</h4>
                  </div>
    
                  <div class="card-body pl-0">
                  {/*
                    <p class="para-content">We will send a link to reset your password</p>
                */}                
                    <form  ref={nameForm}>
                      <div class="form-group">
                        <label for="OldPassword">Old Password</label>
                        <input id="OldPassword" type="password" class="form-control" name="OldPassword" onKeyUp={checkOldPassword} tabindex="1" required autofocus/>
                        <div class="form-group" style={{display:"none"}} id="valMsg3">
                        <small id="emailHelp1" style={{color: "red"}}>
                        please enter correct old password
                        </small>      
                        </div>
                        <label for="NewPassword">New Password</label>
                        <input id="psw" type="password" class="form-control" name="NewPassword" tabindex="1" onChange={onChange} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#@$%&?]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required autofocus/>                        
                        {/*
                        <input id="NewPassword" type="password" class="form-control" name="NewPassword" tabindex="1" required autofocus/> */}
                        <div class="form-group" style={{display:"none"}} id="valMsg4">
                        <small id="emailHelp1" style={{color: "red"}}>
                        please enter correct New password
                        </small>      
                        </div>
                        <label for="ConfirmPassword">Confirm Password</label>
                        <input id="ConfirmPassword" type="password" class="form-control" name="ConfirmPassword" onChange={handleConfirm} tabindex="1" required autofocus/>
                        <div class="form-group" style={{display:"none"}} id="valMsg5">
                        <small id="emailHelp1" style={{color: "red"}}>
                        password not matched, please enter correct Confirm password
                        </small>      
                        </div>
                      </div>

                      <div class="form-group" style={{display:"none"}} id="valMsg2">
                      <small id="emailHelp" style={{color: "red"}}>
                      please enter correct password to Confirm
                      </small>      
                      </div>
                      <div id="message" style={{display:"none"}}>
                        <p>Password must contain the following:</p>
                        <p id="letter" class="invalid">A <b>lowercase</b> letter</p>
                        <p id="capital" class="invalid">A <b>capital (uppercase)</b> letter</p>
                        <p id="number" class="invalid">A <b>number</b></p>
                        <p id="length" class="invalid">Minimum <b>8 characters</b></p>
                        <p id="SpecialChar" class="invalid">A <b>Special characters</b></p>
                      </div>

                    <div class="form-group" style={{display:"none"}} id="resetPassNotification">
                      <small id="emailHelp" style={{color: "red"}}>
                        Password changed successfully please click to login
                      </small>      
                    </div>
    
                      <div class="form-group">
                        <button type="submit" class="btn btn-primary  btn-block" tabindex="4" onClick={handlePasswordSave}>
                          Submit
                        </button>
                      </div>
                      <div> <Link to='/login'>Go to Login</Link></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
    
          </div>
        </section>
      </div>
        </div>
    )
}

export default ResetPassword

