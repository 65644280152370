import React from 'react'
import { ColorRing } from 'react-loader-spinner'

function Loader({data}) {
    return (
        <div className='loader'>
            
            <ColorRing
                visible={data}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#aa1129','#aa1129','#aa1129','#aa1129','#aa1129']}
            />
        </div>
    )
}

export default Loader